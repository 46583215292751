import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ConfirmDialog} from '../../interfaces/DataModel';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  title: string;
  description: string;
  okButtonText: string;
  cancelButtonText: string;

  constructor(
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialog) {
  }

  async ngOnInit() {
    this.title = this.data.hasOwnProperty('title') ?
      this.data.title :
      await this.translateService.get('default_confirm_title').toPromise();

    this.description = this.data.hasOwnProperty('description') ?
      this.data.description :
      await this.translateService.get('default_confirm_description').toPromise();

    this.okButtonText = this.data.hasOwnProperty('okButtonText') ?
      this.data.okButtonText :
      await this.translateService.get('default_confirm_ok_button_text').toPromise();

    this.cancelButtonText = this.data.hasOwnProperty('cancelButtonText') ?
      this.data.cancelButtonText :
      await this.translateService.get('default_confirm_cancel_button_text').toPromise();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
