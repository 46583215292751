import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SpeedDialFabAnimations} from '../../../animations/speed-dial-fab.animations';
import {SafeHtml} from '@angular/platform-browser';
import {listAnimation} from '../../../animations/system.animation';
import {CallMeComponent} from '../../call-me/call-me/call-me.component';
import {MatDialog} from '@angular/material/dialog';
import {ShoppingCartService} from '../../shopping-cart/shopping-cart.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ScopeService} from '../../../services/scope.service';
import {Router} from '@angular/router';
import {OrderService} from '../../../pages/account/orders/order.service';
import {AuthenticationService} from '../../../pages/authentication/authentication.service';
import {LocalStorageService} from '../../../services/local-storage.service';
import {environment} from '../../../../environments/environment';
// tslint:disable-next-line:one-variable-per-declaration
import {TweenMax} from 'gsap/dist/gsap';
declare var $; //, TweenMax;

@Component({
  selector: 'app-quick-menu',
  templateUrl: './quick-menu.component.html',
  styleUrls: ['./quick-menu.component.scss'],
  animations: [SpeedDialFabAnimations, listAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickMenuComponent implements OnInit, OnDestroy, OnChanges {

  fabButtons: { key: string, icon: string | SafeHtml, name: string }[] = [];
  buttons = [];
  @Input() connection: any;
  fabTogglerState = 'inactive';
  runDelay: number = 300;
  destroySubject$: Subject<any> = new Subject<any>();
  translate: { [key: string]: string }[] = [];
  whatsappIcon: string = `<div class="elma-icons white">
      <svg style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z" />
      </svg>
    </div>`;

  constructor(private translateService: TranslateService,
              public dialog: MatDialog,
              private shoppingCartService: ShoppingCartService,
              private scopeService: ScopeService,
              private cd: ChangeDetectorRef,
              private orderService: OrderService,
              private authService: AuthenticationService,
              private localStorage: LocalStorageService,
              private router: Router) {
  }

  async ngOnInit() {
    /*if (this.authService.loggedIn) {
      this.orderService.getActiveMenuOrders()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((activeMenuOrders) => {
      });
    }*/

    this.translate = await this.translateService.get([
      'quick_menu.call',
      'quick_menu.call_me',
      'quick_menu.repeat_last_order',
      'quick_menu.whatsapp',
      'quick_menu.complete_order',
      'quick_menu.directions'
    ]).toPromise();

    this.fabButtons = [
      /*{
        key: 'repeat_last_order',
        icon: '<i class="material-icons">refresh</i>',
        name: x['quick_menu.repeat_last_order'] + ' <small>(3 ürün, 4.040,00 TL)</small>'
      },*/
      {
        key: 'call',
        icon: '<i class="material-icons">phone</i>',
        name: this.translate['quick_menu.call']
      },
      {
        key: 'call_me',
        icon: '<i class="material-icons">ring_volume</i>',
        name: this.translate['quick_menu.call_me']
      },
      {
        key: 'whatsapp',
        icon: this.whatsappIcon,
        name: this.translate['quick_menu.whatsapp']
      },
    ];

    this.shoppingCartService.shoppingCart$
    .pipe(takeUntil(this.destroySubject$))
    .subscribe((shoppingCart) => {
      if (shoppingCart &&
        shoppingCart.hasOwnProperty('shoppingCartItems') &&
        shoppingCart.shoppingCartItems.length > 0) {

        const obj = {
          key: 'complete_order',
          icon: `<i class="material-icons-outlined">shopping_cart</i>`,
          name: this.translate['quick_menu.complete_order'] +
            `<span class="ms-2 fs-12">(` + shoppingCart.shoppingCartItems.length + ` ürün, ` +
            this.scopeService.priceFormatter(shoppingCart.priceFormatted, true) + `)</span>`
        };

        if (!this.fabButtons.some(x => x.key === 'complete_order')) {
          this.fabButtons.push(obj);
        } else {
          const index = this.fabButtons.findIndex(x => x.key === 'complete_order');
          this.fabButtons[index] = obj;
        }
      } else {
        const index = this.fabButtons.findIndex(x => x.key === 'complete_order');
        if (index > -1) {
          this.fabButtons.splice(index, 1);
        }
      }
    });

    if (this.authService.auth?.roles.some(x => x === 'adminPanel')) {
      this.fabButtons.push({
        key: 'admin_panel_home_page',
        icon: `<span class="mdi mdi-shield-account fs-23"></span>`,
        name: 'Admin Panel'
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.connection = changes.connection.currentValue;
    this.cd.detectChanges();
  }

  run(event: string) {
    this.onToggleFab();
    this[event]();
  }

  showItems() {
    this.fabTogglerState = 'active';
    this.buttons = this.fabButtons;
  }

  hideItems() {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }

  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }

  call() {
    this.vibrate('call');
    setTimeout(() => {
      window.location.href = 'tel:905459034890';
    }, this.runDelay);
  }

  whatsapp() {
    this.vibrate('whatsapp');
    setTimeout(() => {
      window.location.href = 'https://api.whatsapp.com/send?phone=905459034890';
    }, this.runDelay);
  }

  call_me() {
    this.vibrate('call_me');
    setTimeout(() => {

      const dialogRef = this.dialog.open(CallMeComponent, {
        panelClass: 'no-padding-dialog',
        disableClose: true,
        maxWidth: '94%',
        width: '400px',
      });

      /*dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
      });*/

    }, this.runDelay);
  }

  repeat_last_order() {
    this.vibrate('repeat_last_order');
    setTimeout(() => {
      alert('Bu buton daha önce herhangi bir sipariş verdiyse görüntülenecek.');
    }, this.runDelay);
  }

  complete_order() {
    this.vibrate('complete_order');
    setTimeout(() => {
      this.router.navigate(['/payment']).then();
    }, this.runDelay);
  }

  admin_panel_home_page() {
    this.localStorage.getItem('auth');
    const url = new URL(environment.adminUrl);
    const auth = this.localStorage.getItem('auth');
    url.searchParams.append('auth', btoa(unescape(encodeURIComponent(JSON.stringify(auth)))));
    window.location.href = url.toString();
  }

  directions() {
    this.vibrate('directions');
    setTimeout(() => {
      window.location.href = 'http://maps.google.com/?saddr=Current%20Location&daddr=90Fit | Adrese Teslim Diyet Yemek';
    }, this.runDelay);
  }

  vibrate(row: string) {
    TweenMax.to($('.row-' + row + ' .material-icons, .row-' + row + ' .elma-icons'), 0.03, {x: '+=3', yoyo: true, repeat: 3});
  }

  ngOnDestroy() {
    this.destroySubject$.next();
  }
}
