import {
  HttpInterceptor as HttpInterceptorModel,
  HttpRequest,
  HttpHandler,
  HttpResponse,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {NotificationService} from '../services/notification.service';
import {throwError} from 'rxjs';

@Injectable()
export class HttpInterceptor implements HttpInterceptorModel {

  constructor(private notification: NotificationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    // if (navigator.onLine) {
      let newRequest: HttpRequest<any>;
      const requestUrl = request.url.replace(/\/$/, '');

      newRequest = request.clone({
        url: requestUrl,
        method: request.method,
        headers: request.headers
        .set('Content-Type', `application/vnd.example.v1+json`)
        .set('Accept', `application/json`)
        .set('AppUuid', environment.appUuid)
      });

      return next.handle(newRequest).pipe(
        tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse && !event?.body) {
              return;
            }

            if (event instanceof HttpResponse &&
              ['POST', 'PUT', 'DELETE', 'GET'].indexOf(newRequest.method) > -1 &&
              event.body.hasOwnProperty('hasSuccess')) {
              this.notification.success(event.body.hasSuccess);
              delete event.body.hasSuccess;
            }
          },
          async (error: HttpErrorResponse) => {
            if (error.hasOwnProperty('error') &&
              error.error.hasOwnProperty('hasError') &&
              error.error.hasError) {

              this.notification.error(error.error.hasError);
            } else if (error.hasOwnProperty('error') &&
              error.error.hasOwnProperty('formError') &&
              error.error.formError) {

              let message = '';
              Object.keys(error.error.formError).map((item, index) => {
                message += error.error.formError[item] +
                  ((Object.keys(error.error.formError).length - 1) > index ? '<br>' : '');
              });

              this.notification.error(message);
            } else if (error.hasOwnProperty('error') &&
              error.error.hasOwnProperty('message') &&
              error.error.message) {

              this.notification.error(error.error.message);
            } else if (error.hasOwnProperty('statusText')) {
              // Hata durumunda snackbar ile gelen mesaj gosterildi.
              this.notification.error(error.statusText);
            }
          }, () => {
          }
        )
      );
    /*} else {
      return throwError(new HttpErrorResponse({
        status: 0,
        error: {
          description: 'Check Connectivity!'
        },
        statusText: 'Check Connectivity!'
      }));
    }*/
  }
}
