import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import {DateAdapter} from '@angular/material/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {HttpCancelService} from './services/http-cancel.service';
import {fadeAnimation, pageFadeAnimation} from './animations/system.animation';
import {SocketService} from './services/socket.service';
import {MyService} from './services/my.service';
import {AuthenticationService} from './pages/authentication/authentication.service';
import {of, Subject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {AdsBlockComponent} from './dialogs/ads-block/ads-block.component';
import {LocalStorageService} from './services/local-storage.service';
import {environment} from '../environments/environment';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {takeUntil} from 'rxjs/operators';
import {HelpDialogComponent} from './dialogs/help-dialog/help-dialog.component';
import {LocalStorage} from 'ngx-webstorage';
import {ShoppingCartService} from './modules/shopping-cart/shopping-cart.service';
import {ContentService} from './services/content.service';
import {
  mdbArrowBottomIcon, mdbBMIIcon, mdbCalorieCalculatorIcon, mdbCommentIcon,
  mdbDietitianIcon, mdbEggIcon, mdbExternalLinkIcon, mdbFavActiveIcon,
  mdbFavIcon,
  mdbHomeIcon, mdbInfoIcon,
  mdbNotificationIcon, mdbPlayIcon,
  mdbProgramsIcon, mdbRecipesIcon, mdbSetTargetIcon, mdbShoppingBasketIcon, mdbShoppingListIcon,
  mdbSpecialForMeIcon,
  mdbStarIcon
} from './helpers/icons';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer, Meta} from '@angular/platform-browser';
import {FacebookPixelService} from './services/facebook-pixel.service';
import {AppInitService} from './services/app-init.service';

declare var window; // canRunAds

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeAnimation,
    pageFadeAnimation,
  ]
})
export class AppComponent implements OnInit, OnDestroy {

  destroySubject$: Subject<any> = new Subject<any>();
  @LocalStorage() whatsappDialog: boolean;
  writing: string[] = [];

  constructor(
    private renderer: Renderer2,
    private translate: TranslateService,
    private httpCancelService: HttpCancelService,
    private router: Router,
    private socketService: SocketService,
    private myService: MyService,
    private authService: AuthenticationService,
    private dateAdapter: DateAdapter<any>,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private idle: Idle,
    private meta: Meta,
    private shoppingCartService: ShoppingCartService,
    private cd: ChangeDetectorRef,
    private contentService: ContentService,
    private fps: FacebookPixelService,
    private localStorage: LocalStorageService,
    private appInitService: AppInitService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) {
    this.translate.setDefaultLang('tr');
    this.translate.use('tr');
    this.dateAdapter.setLocale('tr');

    iconRegistry.addSvgIconLiteral('mdb-star', sanitizer.bypassSecurityTrustHtml(mdbStarIcon));
    iconRegistry.addSvgIconLiteral('mdb-notification', sanitizer.bypassSecurityTrustHtml(mdbNotificationIcon));
    iconRegistry.addSvgIconLiteral('mdb-home', sanitizer.bypassSecurityTrustHtml(mdbHomeIcon));
    iconRegistry.addSvgIconLiteral('mdb-programs', sanitizer.bypassSecurityTrustHtml(mdbProgramsIcon));
    iconRegistry.addSvgIconLiteral('mdb-special-for-me', sanitizer.bypassSecurityTrustHtml(mdbSpecialForMeIcon));
    iconRegistry.addSvgIconLiteral('mdb-dietitian', sanitizer.bypassSecurityTrustHtml(mdbDietitianIcon));
    iconRegistry.addSvgIconLiteral('mdb-fav', sanitizer.bypassSecurityTrustHtml(mdbFavIcon));
    iconRegistry.addSvgIconLiteral('mdb-fav-active', sanitizer.bypassSecurityTrustHtml(mdbFavActiveIcon));
    iconRegistry.addSvgIconLiteral('mdb-external-link', sanitizer.bypassSecurityTrustHtml(mdbExternalLinkIcon));
    iconRegistry.addSvgIconLiteral('mdb-arrow-bottom', sanitizer.bypassSecurityTrustHtml(mdbArrowBottomIcon));
    iconRegistry.addSvgIconLiteral('mdb-comment', sanitizer.bypassSecurityTrustHtml(mdbCommentIcon));
    iconRegistry.addSvgIconLiteral('mdb-shopping-list', sanitizer.bypassSecurityTrustHtml(mdbShoppingListIcon));
    iconRegistry.addSvgIconLiteral('mdb-recipes', sanitizer.bypassSecurityTrustHtml(mdbRecipesIcon));
    iconRegistry.addSvgIconLiteral('mdb-info', sanitizer.bypassSecurityTrustHtml(mdbInfoIcon));
    iconRegistry.addSvgIconLiteral('mdb-shopping-basket', sanitizer.bypassSecurityTrustHtml(mdbShoppingBasketIcon));
    iconRegistry.addSvgIconLiteral('mdb-play', sanitizer.bypassSecurityTrustHtml(mdbPlayIcon));
    iconRegistry.addSvgIconLiteral('mdb-bmi', sanitizer.bypassSecurityTrustHtml(mdbBMIIcon));
    iconRegistry.addSvgIconLiteral('mdb-set-target', sanitizer.bypassSecurityTrustHtml(mdbSetTargetIcon));
    iconRegistry.addSvgIconLiteral('mdb-calorie-calculator', sanitizer.bypassSecurityTrustHtml(mdbCalorieCalculatorIcon));
    iconRegistry.addSvgIconLiteral('mdb-egg', sanitizer.bypassSecurityTrustHtml(mdbEggIcon));

    if (!environment.production) {
      meta.addTag({name: 'robots', content: 'noindex'});
    }
  }

  ngOnInit(): void {
    this.localStorageService.storageSubject
    .pipe(takeUntil(this.destroySubject$))
    .subscribe(() => {
      const campaigns = this.localStorageService.getItem('system.campaigns');
      if (campaigns) {
        this.contentService.products({
          campaign: true,
        })
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(products => {
          this.contentService.campaigns$.next(products.list);
        });
      }

      this.cd.detectChanges();
    });

    if (!this.whatsappDialog) {
      this.idle.setIdle(1); // sayma başlangıcı
      this.idle.setTimeout(60 * 2); // beklenecek süre
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
      this.idle.onTimeout.subscribe(() => {
        this.whatsappDialog = true;
        this.dialog.open(HelpDialogComponent, {
          maxWidth: '94%',
          width: '400px',
          panelClass: ['no-padding-dialog', 'whatsapp-dialog'],
        });
      });
      this.idle.watch();
    }

    /*if (typeof canRunAds === 'undefined') {
      this.dialog.open(AdsBlockComponent, {
        panelClass: 'no-padding-dialog',
        disableClose: true,
        maxWidth: '400px',
        width: '94%'
      });
    }*/

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.fps.track('PageView');
      }
    });

    this.appInitService
    .utmControl(this.route.snapshot.queryParams)
    .pipe(takeUntil(this.destroySubject$))
    .subscribe();

    // console.log(this.router);
    this.route.queryParams.subscribe((queryParams) => {
      this.appInitService
      .utmControl(queryParams)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe();

      if (queryParams.hasOwnProperty('provider') &&
        queryParams.hasOwnProperty('token')) {
        this.authService.loginWithToken({
          provider: queryParams.provider,
          token: queryParams.token,
          userUuid: queryParams?.userUuid,
          session: this.localStorageService.getItem('system.session'),
        }).subscribe(() => {
          const redirect = queryParams?.redirect || this.localStorageService.getItem('redirect');
          if (redirect) {
            this.localStorageService.removeItem('redirect');

            let redirectUrl = atob(redirect);

            if (redirectUrl.indexOf(environment.adminUrl) > -1) {
              const url = new URL(redirectUrl);
              const auth = this.localStorageService.getItem('auth');
              url.searchParams.append('auth', btoa(unescape(encodeURIComponent(JSON.stringify(auth)))));
              redirectUrl = url.toString();
            }

            window.location.href = redirectUrl;
          } else {
            window.location.href = '/';
          }
        });
      }
    });

    this.shoppingCartService
    .getShoppingCart()
    .pipe(takeUntil(this.destroySubject$))
    .subscribe();
  }

  /*setDomain(event: NavigationStart) {
    const url = event.url;

    if (window.location.hostname === '90fit.com.tr') {
      if (url.indexOf('/programs') > -1 || url.indexOf('special-for-me') > -1) {
        // window.location.href = 'https://eattkit.com' + url;
        this.redirect('https://eattkit.com' + url);
      }
    }
    else if (window.location.hostname === 'eattkit.com') {
      if (url.indexOf('/programs') === -1 && url.indexOf('special-for-me') === -1) {
        /!*if (url === '/') {
          // window.location.href = 'https://eattkit.com/programs';
          this.redirect('https://eattkit.com/programs');
        } else {*!/
          // window.location.href = 'https://90fit.com.tr' + url;
          this.redirect('https://90fit.com.tr' + url);
        // }
      }
    }
  }*/

  redirect(url: string) {
    this.localStorage.getItem('auth');
    const uri = new URL(url);
    const auth = this.localStorage.getItem('auth');

    if (auth) {
      uri.searchParams.append('auth', btoa(unescape(encodeURIComponent(JSON.stringify(auth)))));
    }

    window.location.href = uri.toString();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event?.key) {
      this.writing.push(event.key.toLowerCase());

      if (this.writing.join('').indexOf('!!!') > -1) {
        window.location.href = 'https://eattkit.com';
        this.writing = [];
      }
    }
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  ngOnDestroy(): void {
    this.writing = [];
    this.destroySubject$.next();
  }
}
