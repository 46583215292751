import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../pages/authentication/authentication.service';
import {NotificationService} from '../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class VerifiedGuard implements CanActivate {

  constructor(private authService: AuthenticationService,
              private notificationService: NotificationService,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.authService.loggedIn && !this.authService.auth.profile.emailVerifiedAt) {

      this.notificationService.warn('Devam etmek için lütfen önce e-posta adresinizi doğrulayınız.');

      if (this.router.url !== '/account/update') {
        this.router.navigate(['/account/update']).then();
      }

      return false;
    }

    return true;
  }

}
