import {Inject, Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {Module, Phone, ShoppingCartItem, File} from '../interfaces/DataModel';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {environment} from '../../environments/environment';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import {LocalStorageService} from './local-storage.service';
import {AuthenticationService} from '../pages/authentication/authentication.service';
import {AllValidationErrors, FormGroupControls} from '../interfaces/Extends';
import {TranslateService} from '@ngx-translate/core';

declare var $;

@Injectable({
  providedIn: 'root'
})
export class ScopeService {

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showLoading: boolean = true;
  loadingProgress$: Subject<number> = this.loadingBar.progress$;

  responsiveSizes: { [screen: number]: number } = {
    100: 100,
    150: 150,
    300: 300,
    600: 600,
    768: 768,
    1024: 1024,
    1080: 600,
  };

  menu: BehaviorSubject<Module[]> = new BehaviorSubject<Module[]>([
    {
      name: 'sidebar.home_page',
      link: '/',
      key: 'home_page',
      disabled: false,
    },
    {
      name: 'sidebar.programs',
      link: '/products',
      key: 'programs',
      disabled: false,
    },
    {
      name: 'sidebar.campaigns',
      link: '/campaigns',
      key: 'campaigns',
      disabled: false,
    },
    {
      name: 'sidebar.online',
      link: '/online',
      key: 'online',
      disabled: true,
    },
    {
      name: 'sidebar.about',
      link: '/pages/hakkimizda',
      key: 'about',
      disabled: false,
    },
    {
      name: 'sidebar.blog',
      link: '/blog',
      key: 'blog',
      disabled: false,
    },
    {
      name: 'sidebar.contact',
      link: '/pages/iletisim',
      key: 'contact',
      disabled: false,
    }
  ]);

  constructor(private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private authService: AuthenticationService,
              private translateService: TranslateService,
              private loadingBar: LoadingBarService) {
    this.loadingBar.progress$.subscribe(v => {
      if (this.showLoading) {
        this.loading$.next(v > 0);
        this.spinner[v > 0 ? 'show' : 'hide']();
      } else {
        this.showLoading = true;
      }
    });
  }

  buildProductImage(image: File, withHeight: boolean = true, secure: boolean = false): string {
    let url = '';

    if (!image) {
      return url;
    }

    Object.keys(this.responsiveSizes)
    .map((screen, index) => {
      url += environment.services.fileService + image.uuid + '?' +
        (secure && this.authService.loggedIn ?
          'accessToken=' + this.authService.auth.token + '&' : '') +
        'width=' + this.responsiveSizes[screen] +
        (withHeight ? '&height=' + this.responsiveSizes[screen] : '') + ' ' + screen + 'w';

      if (Object.keys(this.responsiveSizes).length - 1 > index) {
        url += ', ';
      }
    });
    return url;
  }

  securedFileUrl(url: string) {
    return this.authService.loggedIn ?
      url + (url.indexOf('?') > -1 ? url + '&' : '?') + 'accessToken=' + this.authService.auth.token
      : url;
  }

  phoneFormatted(phone?: string) {
    if (phone) {
      return parsePhoneNumberFromString(phone);
    }
  }

  matchHeight(innerDiv) {
    if (innerDiv === undefined) {
      innerDiv = '.card';
    }
    let currentTallest = 0;
    let currentRowStart = 0;
    const rowDivs = [];
    let topPosition = 0;
    $('.match-height-parent').each(function() {
      $(this).find(innerDiv).height('auto');
      topPosition = $(this).position().top;
      if (currentRowStart !== topPosition) {
        for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
          rowDivs[currentDiv].find(innerDiv).height(currentTallest);
        }
        rowDivs.length = 0;
        currentRowStart = topPosition;
        currentTallest = $(this).find(innerDiv).height();
        rowDivs.push($(this));
      } else {
        rowDivs.push($(this));
        currentTallest = (currentTallest < $(this).find(innerDiv).height()) ? ($(this).find(innerDiv).height()) : (currentTallest);
      }

      for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
        rowDivs[currentDiv].find(innerDiv).height(currentTallest);
      }
    });
  }

  getDeviceType = () => {
    const ua = window.navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'MOBILE_TABLET';
    }
    if (
      /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'MOBILE_WEB';
    }
    return 'WEB';
  };

  // verilen string'in ilk karakterini büyük, diğer tüm karakterleri küçük harf olarak döndürür
  ucfirst(text: string) {
    if (!text) {
      return text;
    }
    return text.charAt(0).toLocaleUpperCase() + text.slice(1).toLocaleLowerCase();
  }

  // verilen string'in her kelimesinin ilk karakterini büyük, diğer tüm karakterleri küçük harf olarak döndürür
  ucwords(text: string) {
    if (!text) {
      return text;
    }

    const str = [];

    text.split(' ').forEach(x => {
      str.push(this.ucfirst(x));
    });

    return str.join(' ');
  }

  favoriteToggle() {
    // console.log(this.route.snapshot.url);
  }

  makeId(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  checkFavorite() {
    return false;
  }

  shoppingListParser(list: ShoppingCartItem[]) {
    if (!list || list.length === 0) {
      return [
        [], []
      ];
    }

    return [
      list.filter(x => x.rowType === 'product'),
      list.filter(x => x.rowType === 'coupon'),
    ];
  }

  checkTcNum(control: AbstractControl): ValidationErrors | null {
    const value = control.value + '';
    const isEleven = /^[0-9]{11}$/.test(value);
    let totalX = 0;
    for (let i = 0; i < 10; i++) {
      totalX += Number(value.substr(i, 1));
    }
    const isRuleX = totalX % 10 === +value.substr(10, 1);
    let totalY1 = 0;
    let totalY2 = 0;
    for (let i = 0; i < 10; i += 2) {
      totalY1 += Number(value.substr(i, 1));
    }
    for (let i = 1; i < 10; i += 2) {
      totalY2 += Number(value.substr(i, 1));
    }
    const isRuleY = ((totalY1 * 7) - totalY2) % 10 === +value.substr(9, 0);
    const result = isEleven && isRuleX && isRuleY;
    return !result ? {tcIdentity: isEleven && isRuleX && isRuleY} : null;
  }

  checkTaxNumber(control: AbstractControl): ValidationErrors | null {
    const kno = '' + control.value;

    if (kno.length === 10) {
      const v = [];
      const lastDigit = Number(kno.charAt(9));
      for (let i = 0; i < 9; i++) {
        const tmp = (Number(kno.charAt(i)) + (9 - i)) % 10;
        v[i] = (tmp * 2 ** (9 - i)) % 9;
        if (tmp !== 0 && v[i] === 0) {
          v[i] = 9;
        }
      }
      const sum = v.reduce((a, b) => a + b, 0) % 10;

      const validate = (10 - (sum % 10)) % 10 === lastDigit;
      return !validate ? {checkTaxNumber: true} : null;
    }
    return null;
  }

  getValidationError(control: string, controls: FormGroupControls) {
    const errors = this.getFormValidationErrors(controls);

    const err = errors.find(error => error.control_name === control);
    if (!err) {
      return false;
    } else {
      let message;
      switch (err.error_name) {
        default:
          message = of('Veri hatalı');
          break;
        case 'max':
          message = this.translateService.get('validation_errors.max', err.error_value);
          break;
        case 'min':
          message = this.translateService.get('validation_errors.min', err.error_value);
          break;
        case 'required':
          message = this.translateService.get('validation_errors.required', err.error_value);
          break;
        case 'regex':
          message = this.translateService.get('validation_errors.regex', err.error_value);
          break;
        case 'exists':
          message = this.translateService.get('validation_errors.exists', err.error_value);
          break;
        case 'confirm':
          message = this.translateService.get('validation_errors.confirm', err.error_value);
          break;
        case 'minlength':
          message = this.translateService.get('validation_errors.minLength', err.error_value);
          break;
        case 'maxlength':
          message = this.translateService.get('validation_errors.maxLength', err.error_value);
          break;
        case 'notEqual':
          message = this.translateService.get('validation_errors.notEqual', err.error_value);
          break;
      }
      return message;
    }
  }

  getFormValidationErrors(controls: FormGroupControls): AllValidationErrors[] {
    let errors: AllValidationErrors[] = [];
    Object.keys(controls).forEach(key => {
      const control = controls[key];
      if (control instanceof FormGroup) {
        errors = errors.concat(this.getFormValidationErrors(control.controls));
      }
      const controlErrors: ValidationErrors = controls[key].errors;
      if (controlErrors !== null) {
        Object.keys(controlErrors).forEach(keyError => {
          errors.push({
            control_name: key,
            error_name: keyError,
            error_value: controlErrors[keyError]
          });
        });
      }
    });
    return errors;
  }

  lightenDarkenColor(col, amt) {
    let usePound = false;

    if (col[0] === '#') {
      col = col.slice(1);
      usePound = true;
    }

    const num: number = parseInt(col, 16);

    // tslint:disable-next-line:no-bitwise
    let r = (num >> 16) + amt;

    if (r > 255) {
      r = 255;
    } else if (r < 0) {
      r = 0;
    }

    // tslint:disable-next-line:no-bitwise
    let b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) {
      b = 255;
    } else if (b < 0) {
      b = 0;
    }

    // tslint:disable-next-line:no-bitwise
    let g = (num & 0x0000FF) + amt;

    if (g > 255) {
      g = 255;
    } else if (g < 0) {
      g = 0;
    }

    // tslint:disable-next-line:no-bitwise
    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);

  }

  isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  priceFormatter(price: string, small: boolean = true) {
    if (price) {
      small = true;
      const parsed = price.split(',');
      const tag = small ? 'small' : 'span';
      return parsed[0] + (parsed[1] !== '00' ?

        '<' + tag + '>,' + parsed[1] + '₺</' + tag + '>' :
        '<' + tag + '>,' + parsed[1] + '₺</' + tag + '>');
    }

    return null;
  }

  phoneFormatter(phone: Phone) {
    if (phone.value.length === 13) {

    }

    return phone.value;
  }
}
