import {Inject, Injectable} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import {DOCUMENT} from '@angular/common';
declare var $;

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  drawer: MatDrawer;
  drawerBackdrop: boolean = true;
  drawerMode: 'push' | 'over' | 'side' = 'push';
  drawerLocked: boolean = true;
  drawerIsOpen: boolean = false;

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  lockSidebar(): void {
    this.drawerMode = 'push';
    this.drawerBackdrop = false;
    this.drawerLocked = true;
  }

  unlockSidebar(): void {
    this.drawerMode = 'over';
    this.drawerBackdrop = true;
    this.drawerLocked = false;
  }

  toggleSidebar(): void {
    if (this.drawer.opened) {
      this.drawer.close().then();
      this.drawerIsOpen = false;
    } else {
      this.drawer.open().then();
      this.drawerIsOpen = true;
    }
  }

  closeSidebar(): void {
    this.drawerIsOpen = false;

    if (this.drawer.opened) {
      this.drawer.close().then();
    }
  }

  openSidebar(): void {
    if (!this.drawer.opened) {
      this.drawer.open().then();
      this.drawerIsOpen = true;
    }
  }

  scrollTo(element: string,
           toElement: string | number = 0,
           speed: number = 400) {
    let toElementDom;

    if (typeof toElement === 'string') {
      toElementDom = this.document.querySelector(toElement);
    }

    $(element).animate({
      scrollTop: typeof toElement === 'string' ?
        toElementDom.offsetTop > 10 ?
          (toElementDom.offsetTop - 10) + 'px' : toElementDom.offsetTop + 'px' :
        toElement + 'px'
    }, speed);
  }

  collapseToggle(event: MouseEvent): any {
    /*document.querySelector(event.fromElement);

    if (!event.fromElement.classList.contains('open')) {
        event.fromElement.classList.add('open');
    } else {
        event.fromElement.classList.remove('open');
    }*/
  }
}
