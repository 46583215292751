<!--<mat-fab-menu color="primary"
              class="quick-fab"
              [icon]="'shuffle'"
              (onFabMenuItemSelected)="run($event)"
              [fabButtons]="quickMenu">
</mat-fab-menu>-->

<div class="fab-container">
  <button mat-fab class="fab-toggler"
          color="primary"
          aria-label="hızlı menü"
          [disabled]="!connection"
          (click)="onToggleFab()">
    <img src="/assets/icons/unnamed2.png" style="width: 30px" alt="support" />
    <!--<i class="material-icons"
       [style.fontSize.px]="30"
       [@fabToggler]="{value: fabTogglerState}">
      {{fabTogglerState === 'active' ? 'close' : 'directions_run'}}
    </i>-->
  </button>
  <div [@speedDialStagger]="buttons.length" class="d-flex">
    <div *ngFor="let btn of buttons"
         [class]="'row-' + btn.key + ' d-flex align-items-center'"
         (click)="run(btn.key)"
         class="d-flex flex-row">
      <div matRipple [innerHTML]="btn.name | safeHtml"
           class="mat-elevation-z1 d-flex flex-grow-0"></div>
      <button mat-mini-fab
              aria-label="menu"
              class="fab-secondary d-flex flex-grow-0 align-items-center"
              color="primary"
              fxLayoutAlign="center center"
              mat-raised-button
              [innerHTML]="btn.icon | safeHtml">
      </button>
    </div>
  </div>
</div>
<div id="fab-dismiss"
     @fade
     *ngIf="fabTogglerState==='active'"
     (click)="onToggleFab()">
</div>
