import {
  HttpInterceptor as HttpInterceptorModel,
  HttpRequest,
  HttpHandler, HttpEvent, HttpErrorResponse,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {tap} from 'rxjs/operators';
import {ShoppingCartService} from '../modules/shopping-cart/shopping-cart.service';

@Injectable()
export class ShoppingCartInterceptor implements HttpInterceptorModel {

  constructor(public shoppingCardService: ShoppingCartService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        },
        async (error: HttpErrorResponse) => {
          if (error.hasOwnProperty('error') &&
            error.error.hasOwnProperty('formError') &&
            error.error.formError &&
            error.error.formError.hasOwnProperty('session')) {

            this.shoppingCardService
            .getShoppingCart()
            .subscribe();
          }
        })
    );
  }
}
