import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Meta} from '@angular/platform-browser';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent implements OnInit {

  constructor(private meta: Meta) {
    meta.addTag({name: 'robots', content: 'noindex'});
  }

  ngOnInit() {
  }

}
