import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DefaultLayoutComponent} from './default-layout.component';
import {SidebarComponent} from './includes/sidebar/sidebar.component';
import {ToolbarComponent} from './includes/toolbar/toolbar.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {DirectivesModule} from '../../directives/directives.module';
import {BreadcrumbModule} from 'xng-breadcrumb';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {QuickMenuModule} from '../../modules/quick-menu/quick-menu.module';
import {MatTabsModule} from '@angular/material/tabs';
import {MatListModule} from '@angular/material/list';
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ShoppingCartModule} from '../../modules/shopping-cart/shopping-cart.module';
import {NgxSpinnerModule} from 'ngx-spinner';
import {MdePopoverModule} from '@material-extended/mde';
import {MatCardModule} from '@angular/material/card';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [
    SidebarComponent,
    ToolbarComponent,
    DefaultLayoutComponent
  ],
  exports: [
    SidebarComponent,
    ToolbarComponent,
    DefaultLayoutComponent,
  ],
  providers: [],
  imports: [
    PerfectScrollbarModule,
    BreadcrumbModule,
    MatSidenavModule,
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    FlexLayoutModule,
    MatIconModule,
    MatRippleModule,
    CommonModule,
    MatMenuModule,
    TranslateModule,
    RouterModule,
    DirectivesModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    FormsModule,
    MatSlideToggleModule,
    QuickMenuModule,
    MatTabsModule,
    MatListModule,
    LoadingBarModule,
    MatProgressSpinnerModule,
    ShoppingCartModule,
    NgxSpinnerModule,
    MdePopoverModule,
    MatCardModule,
    MatTooltipModule,
  ]
})
export class DefaultLayoutModule {
}
