import {Injectable} from '@angular/core';
import {LocalStorageService} from '../../services/local-storage.service';
import {HttpClient} from '@angular/common/http';
import {Utility} from '../../helpers/Utility';
import {LoginRequest, LoginWithTokenRequest} from '../../interfaces/request/LoginRequest';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {LoginResponse, Profile} from '../../interfaces/response/LoginResponse';
import {AuthStorage, System, User} from '../../interfaces/DataModel';
import {environment} from '../../../environments/environment';
import {FacebookPixelService} from '../../services/facebook-pixel.service';
// tslint:disable-next-line:ban-types
declare let fbq: Function;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements Utility {

  profileChanged$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  constructor(private lss: LocalStorageService,
              private router: Router,
              private fps: FacebookPixelService,
              private http: HttpClient) {
    this.profileChanged$.next(this.user);
  }

  get user(): User {
    return this.lss.getItem('auth') ? this.lss.getItem('auth').profile : {};
  }

  set user(profile: User) {
    if (this.loggedIn) {
      const currentAuth = this.lss.getItem('auth');
      currentAuth.profile = profile;
      this.lss.setItem('auth', currentAuth);
      this.profileChanged$.next(this.user);
    }
  }

  get loggedIn(): boolean {
    return !!this.lss.getItem('auth');
  }

  get auth(): AuthStorage {
    return this.lss.getItem('auth');
  }

  login(authentication: LoginRequest):
    Observable<AuthStorage> {

    return this.http.post<LoginResponse>(environment.services.authService + 'login', authentication)
    .pipe(
      map((obj) => {
        const auth: AuthStorage = {
          profile: obj.object.profile,
          permissions: obj.permissions,
          roles: obj.roles,
          type: obj.object.type,
          token: obj.object.token,
          provider: obj.object.provider,
          expiresAt: obj.object.expiresAt
        };
        this.lss.setItem('auth', auth);
        this.lss.removeItem('utmId');

        const system: System = this.lss.getItem('system');
        system.session = auth.profile.uuid;
        this.lss.setItem('system', system);

        return auth;
      })
    );
  }

  passwordChange(request: any):
    Observable<any> {
    return this.http.post<any>(environment.services.authService + 'passwordChange', request)
    .pipe(
      map((obj) => {
        return obj.object;
      })
    );
  }

  forgotPassword(request: any):
    Observable<any> {
    return this.http.post<any>(environment.services.authService + 'forgotPassword', request)
    .pipe(
      map((obj) => {
        return obj.object;
      })
    );
  }

  resetPassword(request: any):
    Observable<any> {
    return this.http.post<any>(environment.services.authService +
      'password/reset/' + request.token, request)
    .pipe(
      map((obj) => {
        return obj.object;
      })
    );
  }

  checkPasswordTokenStatus(request: { token: string, email: string }):
    Observable<any> {
    return this.http.get<any>(environment.services.authService +
      'password/checkPasswordTokenStatus/' + request.token + '?email=' + request.email)
    .pipe(
      map((obj) => {
        return obj.object;
      })
    );
  }

  register(register: any):
    Observable<AuthStorage> {
    if (this.lss.getItem('utmId')) {
      register.utmId = this.lss.getItem('utmId');
    }

    return this.http.post<any>(environment.services.authService + 'signup', register)
    .pipe(
      map((obj) => {
        this.fps.track('CompleteRegistration');

        const auth: AuthStorage = {
          profile: obj.object.profile,
          permissions: obj.permissions,
          roles: obj.roles,
          type: obj.object.type,
          token: obj.object.token,
          provider: obj.object.provider,
          expiresAt: obj.object.expiresAt
        };
        this.lss.setItem('auth', auth);
        this.lss.removeItem('utmId');

        const system: System = this.lss.getItem('system');
        system.session = auth.profile.uuid;
        this.lss.setItem('system', system);

        return auth;
      })
    );
  }

  verifyWithCode(verifyWithCode: any):
    Observable<AuthStorage> {

    return this.http.post<any>(environment.services.authService + 'email/verifyWithCode', verifyWithCode)
    .pipe(
      map((obj) => {
        const auth: AuthStorage = {
          profile: obj.object.profile,
          permissions: obj.permissions,
          roles: obj.roles,
          type: obj.object.type,
          token: obj.object.token,
          provider: obj.object.provider,
          expiresAt: obj.object.expiresAt
        };
        this.lss.setItem('auth', auth);
        this.lss.removeItem('utmId');

        const system: System = this.lss.getItem('system');
        system.session = auth.profile.uuid;
        this.lss.setItem('system', system);

        return auth;
      })
    );
  }

  loginWithToken(params: LoginWithTokenRequest):
    Observable<AuthStorage> {

    return this.http.post<LoginResponse>(environment.services.authService + 'loginWithToken', params)
    .pipe(
      map((obj) => {
        const auth: AuthStorage = {
          profile: obj.object.profile,
          permissions: obj.permissions,
          roles: obj.roles,
          type: obj.object.type,
          token: obj.object.token,
          provider: obj.object.provider,
          expiresAt: obj.object.expiresAt
        };
        this.lss.setItem('auth', auth);

        const system: System = this.lss.getItem('system');
        system.session = auth.profile.uuid;
        this.lss.setItem('system', system);

        return auth;
      })
    );
  }

  logout(redirect: string = null): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      if (this.loggedIn) {
        await this.http.post(environment.services.authService + 'logout', {}).toPromise();

        this.lss.removeItem('auth');
        this.lss.removeItem('system');

        resolve(true);

      } else {
        resolve(true);
      }

      if (redirect) {
        setTimeout(() => window.location.href = redirect, 1200);
      } else {
        window.location.reload();
      }
    });
  }

  changeEmail(request: { email: string }) {
    return this.http.post<{ email: string }>(environment.services.authService + 'email/change', request)
    .pipe(
      map((obj) => {
        return obj;
      })
    );
  }

  reSend() {
    return this.http.get<{ email: string }>(environment.services.authService + 'email/resend')
    .pipe(
      map((obj) => {
        return obj;
      })
    );
  }

  cancelEmailChange() {
    return this.http.delete<any>(environment.services.authService + 'email/cancelEmailChange')
    .pipe(
      map((obj) => {
        return obj;
      })
    );
  }

  verifyEmail(request: { url: string }):
    Observable<AuthStorage> {
    return this.http.get<LoginResponse>(decodeURIComponent(request.url))
    .pipe(
      map((obj) => {
        const auth: AuthStorage = {
          profile: obj.object.profile,
          permissions: obj.permissions,
          roles: obj.roles,
          type: obj.object.type,
          token: obj.object.token,
          provider: obj.object.provider,
          expiresAt: obj.object.expiresAt
        };
        this.lss.setItem('auth', auth);

        const system: System = this.lss.getItem('system');
        system.session = auth.profile.uuid;
        this.lss.setItem('system', system);

        return auth;
      })
    );
  }
}
