import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {map, share} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private http: HttpClient) { }

  getSecuredFile(uuid: string):
    Observable<any>{
    return this.http.get<ArrayBuffer>(
      environment.services.fileService + uuid,
      {responseType: 'arraybuffer' as 'json'}
    )
    .pipe(
      map((obj) => {
        return new Uint8Array(obj);
      })
    )
    .pipe(share());
  }
}
