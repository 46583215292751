<div class="sidebar-container">
  <!--<div class="navbar-top">
    <div class="logo">
      <img routerLink="/" src="/assets/img/logo.svg" alt="">
      <span class="logo-text"></span>
    </div>
  </div>-->

  <div class="py-2">
    <div class="menu account-menu px-2" fxFlex
         fxLayout="row" fxLayoutGap="10px">
      <div fxFlex>
        <a mat-flat-button
           color="primary"
           class="m-0"
           routerLink="/account"
           fxFlex
           matRipple>
          {{authService.loggedIn ? ('sidebar.account' | translate) : ('sidebar.login' | translate)}}
        </a>
      </div>

      <div fxFlex
           *ngIf="!authService.loggedIn">
        <a mat-flat-button
           fxFlex
           color="primary"
           routerLink="/auth/register"
           class="m-0">
          {{'sidebar.register' | translate}}
        </a>
      </div>
    </div>
  </div>

  <div class="px-3">
    <mat-form-field class="auto-width-input no-padding-input"
                    appearance="outline"
                    fxFlex>
      <mat-label>{{'sidebar.search' | translate}}</mat-label>
      <mat-icon matPrefix
                class="me-2"
                *ngIf="searchRef.value.length > 0"
                (click)="searchRef.value = null">close
      </mat-icon>
      <input matInput
             #searchRef
             autocomplete="off"
             [placeholder]="'sidebar.what_looking' | translate">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>

  <div class="navbar-scroll-container">
    <div perfectScrollbar>
      <mat-tab-group mat-stretch-tabs (selectedIndexChange)="setTab($event)">
        <mat-tab [label]="'sidebar.menu' | translate">
          <mat-list role="list" class="menu">
            <ng-container *ngFor="let menuItem of menu">
              <ng-container *ngIf="!menuItem.disabled">
                <a [routerLink]="!menuItem.disabled ? menuItem.link : null"
                   *ngIf="menuItem.link === '/'; else links2"
                   [ngClass]="{disabled: menuItem.disabled}"
                   mat-list-item
                   [class]="menuItem.key"
                   [routerLinkActiveOptions]="{exact: true}"
                   routerLinkActive="active">
                  {{menuItem.name | translate}}
                </a>
                <ng-template #links2>
                  <a [routerLink]="!menuItem.disabled ? menuItem.link : null"
                     [ngClass]="{disabled: menuItem.disabled}"
                     [class]="menuItem.key"
                     mat-list-item
                     routerLinkActive="active">
                    {{menuItem.name | translate}}
                  </a>
                </ng-template>
              </ng-container>
            </ng-container>
          </mat-list>
        </mat-tab>
        <mat-tab [label]="'sidebar.categories' | translate">
          <mat-list role="list" class="menu">
            <a mat-list-item
               routerLinkActive="active"
               [routerLinkActiveOptions]="{exact: true}"
               routerLink="/products">
              {{'all' | translate}}
            </a>
            <mat-divider></mat-divider>
            <a mat-list-item
               routerLinkActive="active"
               [routerLink]="'/products/' + contentCategory.key"
               *ngFor="let contentCategory of contentCategories">
              {{contentCategory.name}}
            </a>
          </mat-list>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

  <div>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <a href="https://www.instagram.com/90fit_kendineiyibak/"
         mat-button
         class="instagram-button fs-13 align-items-center d-flex flex-row">
        <i class="instagram-icon"></i>90fit_kendineiyibak</a>

      <a (click)="authService.logout()"
         mat-button
         class="logout-button fs-13 align-items-center d-flex flex-row"
         *ngIf="authService.loggedIn">
        {{'sidebar.logout' | translate}}<i class="exit-icon"></i>
      </a>
    </div>
  </div>
</div>
