import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {EatZoneCheckerComponent} from './eat-zone-checker/eat-zone-checker.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatRippleModule} from '@angular/material/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ConfigureComponent} from './configure/configure.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatTabsModule} from '@angular/material/tabs';
import {AddressComponent} from './address/address.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {PaymentProfileComponent} from './payment-profile/payment-profile.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {ExampleMenuComponent} from './example-menu/example-menu.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {MdePopoverModule} from '@material-extended/mde';
import {MatCardModule} from '@angular/material/card';
import {DirectivesModule} from '../directives/directives.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgxMatIntlTelInputModule} from 'ngx-mat-intl-tel-input';
import {PipesModule} from '../pipes/pipes.module';
import {MatAnimationIconModule} from '../modules/mat-animation-icon/mat-animation-icon.module';
import {ChangeEmailComponent} from './change-email/change-email.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {CreditCardComponent} from './credit-card/credit-card.component';
import {NgxMaskModule} from 'ngx-mask';
import {AdsBlockComponent} from './ads-block/ads-block.component';
import {ShowInvoiceComponent} from './show-invoice/show-invoice.component';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {PromoPopupComponent} from './promo-popup/promo-popup.component';
import {RouterModule} from '@angular/router';
import {LastApproveOrderComponent} from './last-approve-order/last-approve-order.component';
import {FriendsInvitationComponent} from './friends-invitation/friends-invitation.component';
import {MatChipsModule} from '@angular/material/chips';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {HelpDialogComponent} from './help-dialog/help-dialog.component';
import {JoyrideModule} from 'ngx-joyride';
import { BimResultModalComponent } from './bim-result-modal/bim-result-modal.component';
import { ProductVideosComponent } from './product-videos/product-videos.component';
import {VideoPlayerModule} from '../modules/video-player/video-player.module';

@NgModule({
    declarations: [
        ConfirmDialogComponent,
        EatZoneCheckerComponent,
        ConfigureComponent,
        AddressComponent,
        PaymentProfileComponent,
        ExampleMenuComponent,
        ChangeEmailComponent,
        CreditCardComponent,
        AdsBlockComponent,
        ShowInvoiceComponent,
        PromoPopupComponent,
        LastApproveOrderComponent,
        FriendsInvitationComponent,
        HelpDialogComponent,
        BimResultModalComponent,
        ProductVideosComponent,
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        FlexModule,
        TranslateModule,
        MatDividerModule,
        MatListModule,
        MatIconModule,
        MatRippleModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        FormsModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
        MatButtonToggleModule,
        PerfectScrollbarModule,
        MdePopoverModule,
        MatCardModule,
        DirectivesModule,
        MatSlideToggleModule,
        MatTooltipModule,
        NgxMatIntlTelInputModule,
        PipesModule,
        MatAnimationIconModule,
        NgxSpinnerModule,
        NgxMaskModule,
        NgxExtendedPdfViewerModule,
        RouterModule,
        MatChipsModule,
        ExtendedModule,
        ClipboardModule,
        JoyrideModule,
        VideoPlayerModule,
    ],
    exports: [
        EatZoneCheckerComponent,
        ConfirmDialogComponent,
        ConfigureComponent,
        AddressComponent,
        PaymentProfileComponent,
        ExampleMenuComponent,
    ]
})
export class DialogsModule {
}
