import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CallMeComponent} from './call-me/call-me.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexModule} from '@angular/flex-layout';
import {TranslateModule} from '@ngx-translate/core';
import {NgxMatIntlTelInputModule} from 'ngx-mat-intl-tel-input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {RouterModule} from '@angular/router';
import {MatRadioModule} from '@angular/material/radio';
import {NgxSpinnerModule} from 'ngx-spinner';

@NgModule({
    declarations: [CallMeComponent],
    exports: [CallMeComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        FlexModule,
        TranslateModule,
        NgxMatIntlTelInputModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        RouterModule,
        MatRadioModule,
        NgxSpinnerModule
    ]
})
export class CallMeModule {
}
