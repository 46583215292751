import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {environment} from 'src/environments/environment';
import {Environment, File} from '../../interfaces/DataModel';
import {AuthenticationService} from '../../pages/authentication/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';

declare let shaka: any;
declare let $: any;

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoPlayerComponent implements OnDestroy, OnChanges {

  @ViewChild('videoPlayer') videoElementRef: ElementRef | undefined;
  @ViewChild('videoContainer') videoContainerRef: ElementRef | undefined;

  videoElement: HTMLVideoElement | undefined;
  videoContainerElement: HTMLDivElement | undefined;
  player: any;


  @Input() video: File;
  @Input() showSaveFrameButton: boolean = false;
  @Input() autoPlay: boolean = false;

  environment: Environment = environment;
  destroySubject$: Subject<void> = new Subject();

  constructor(
    private elementRef: ElementRef,
    private authService: AuthenticationService,
    private translateService: TranslateService
  ) {
  }

  private async initPlayer() {
    if (this.video?.fileType === 'video' && this.videoElementRef?.nativeElement) {
      try {
        shaka.polyfill.installAll();

        if (shaka.Player.isBrowserSupported()) {
          this.videoElement = this.videoElementRef.nativeElement;
          this.videoContainerElement = this.videoContainerRef.nativeElement;
        } else {
          console.error('Browser not supported!');
        }

        const player = new shaka.Player(this.videoElement);
        player.configure({
          preferredTextLanguage: 'tr',
          streaming: {
            bufferingGoal: 4,
            retryParameters: {
              maxAttempts: 10,
              baseDelay: 400,
              backoffFactor: 2,
              fuzzFactor: 0.5,
              timeout: 0,
              stallTimeout: 0,
            }
          },
        });

        const ui = new shaka.ui.Overlay(
          player,
          this.videoContainerElement,
          this.videoElement
        );

        const config = {
          // addSeekBar: true,
          controlPanelElements: ['play_pause', 'mute', 'volume', 'time_and_duration', 'spacer', 'cast', 'airplay', 'overflow_menu', 'fullscreen'],
          overflowMenuButtons: ['quality', 'playback_rate'],
          seekBarColors: {
            base: 'rgba(255,255,255,.2)',
            buffered: 'rgba(255,255,255,.4)',
            played: 'rgb(255,0,0)',
          },
        };
        ui.configure(config);

        const controls = ui.getControls();
        this.player = controls.getPlayer();

        this.player
        .getNetworkingEngine()
        .registerRequestFilter(async (type, request) => {
          if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
            request.headers = {};
            request.headers.AppUuid = environment.appUuid;
            request.headers.lang = `${this.translateService.currentLang ? this.translateService.currentLang : 'tr'}`;
            request.headers['Content-Type'] = `application/x-mpegURL`;

            if (this.authService.loggedIn) {
              request.headers.Authorization = this.authService.auth.type + ' ' + this.authService.auth.token;
            }
          }
        });

        $('.shaka-overflow-menu-button').html('settings');
        $('.shaka-back-to-overflow-button .material-icons-round').html('arrow_back_ios_new');

        await this.player
        .load(environment.services.watchService + this.video.uuid + '?accessToken=' + this.authService.auth?.token);

        this.videoElement?.play();

      } catch (error) {
        console.log(error);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.player) {
      this.player.destroy();
    }
    this.video = changes.video.currentValue;
    setTimeout(() => {
      this.initPlayer().then();
    }, 100);
  }

  ngOnDestroy() {
    this.player.destroy();
    this.destroySubject$.next();
  }
}
