import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from '../pages/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {

  constructor(private authService: AuthenticationService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot):
    boolean {

    if (!this.authService.loggedIn) {
      this.router.navigate(['/auth/login'], {
        queryParams: {
          redirect: btoa(state.url)
        },
      }).then();

      return false;
    }

    return true;
  }
}
