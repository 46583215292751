import {ErrorHandler, Injectable} from '@angular/core';
import {System} from '../interfaces/DataModel';
import {SystemResponse} from '../interfaces/Responses';
import {environment} from '../../environments/environment';
import {map, share} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ErrorException implements ErrorHandler {

  constructor(private http: HttpClient) {
  }

  handleError(error) {
    // alert(JSON.stringify(error));
    /*this.http.post<any>(environment.services.systemService + 'exception', {
      data: JSON.stringify(error)
    }).toPromise().then();*/

    throw error;
  }
}
