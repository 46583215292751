import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {
  FoodDeliveryArea,
  Order,
  OrderItem,
  PagingObject,
  PaymentProfile,
  ShoppingCart
} from '../../../interfaces/DataModel';
import {map, share} from 'rxjs/operators';
import {LocalStorageService} from '../../../services/local-storage.service';
import {
  CheckFoodDeliveryAreaResponse,
  FoodDeliveryAreasResponse,
  OrderItemResponse,
  OrderResponse, PaymentProfileResponse
} from '../../../interfaces/Responses';
import {OrdersResponse} from '../../../interfaces/response/OrdersResponse';
import {Utility} from '../../../helpers/Utility';
import {FacebookPixelService} from '../../../services/facebook-pixel.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  pagination: PagingObject = {
    currentPage: 1,
  };
  orders$: BehaviorSubject<Order[]> = new BehaviorSubject<Order[]>(null);

  constructor(private http: HttpClient,
              private fps: FacebookPixelService,
              private localStorage: LocalStorageService) {
  }

  create(data: any):
    Observable<Order | any> {
    data = Object.assign({}, data, {session: this.localStorage.getItem('system.session')});
    return this.http.post<OrderResponse>(environment.services.orderService, data)
    .pipe(
      map((obj) => {
        return obj.object;
      })
    )
    .pipe(share());
  }

  getFoodDeliveryAreas():
    Observable<FoodDeliveryArea[]> {
    return this.http.get<FoodDeliveryAreasResponse>(environment.services.foodDeliveryAreaService)
    .pipe(
      map((obj) => {
        return obj.list;
      })
    )
    .pipe(share());
  }

  getOrder(request: { code: string }):
    Observable<Order> {
    return this.http.get<OrderResponse>(environment.services.orderService + request.code)
    .pipe(
      map((obj) => {
        return obj.object;
      })
    )
    .pipe(share());
  }

  getActiveMenuOrders():
    Observable<Order[]> {
    return this.http.get<OrdersResponse>(environment.services.orderService + 'activeMenuOrders')
    .pipe(
      map((obj) => {
        return obj.list;
      })
    )
    .pipe(share());
  }

  getInvoice(code: string):
    Observable<any>{
    return this.http.get<ArrayBuffer>(
      environment.services.orderService + 'invoice/' + code,
      {responseType: 'arraybuffer' as 'json'}
    )
    .pipe(
      map((obj) => {
        return new Uint8Array(obj);
      })
    )
    .pipe(share());
  }

  getOrders(setOrders: boolean = true,
            appendOrReset: 'append' | 'reset' = 'reset'):
    Observable<Order[]> {
    return this.http.get<OrdersResponse>(environment.services.orderService, {
      params: Utility.revert({
        page: this.pagination.currentPage,
      })
    })
    .pipe(
      map((obj) => {
        this.pagination = obj.object;

        if (setOrders) {
          if (appendOrReset === 'reset') {
            this.orders$.next(obj.list);
          } else {
            const orders = this.orders$.getValue();
            obj.list.map(x => {
              if (!orders.find(y => y.code === x.code)) {
                orders.push(x);
              }
            });
            this.orders$.next(orders);
          }
        }
        return this.orders$.getValue();
      })
    )
    .pipe(share());
  }

  getOrderItem(request: { uuid: string }):
    Observable<OrderItem> {
    return this.http.get<OrderItemResponse>(environment.services.orderItemService + request.uuid)
    .pipe(
      map((obj) => {
        return obj.object;
      })
    )
    .pipe(share());
  }

  saveOrderItem(request: OrderItem):
    Observable<OrderItem> {
    return this.http.put<OrderItemResponse>(environment.services.orderItemService + request.uuid, request)
    .pipe(
      map((obj) => {
        return obj.object;
      })
    )
    .pipe(share());
  }

  checkFoodDeliveryAreas(neighborhoodIds: number[]):
    Observable<CheckFoodDeliveryAreaResponse> {
    return this.http.post<CheckFoodDeliveryAreaResponse>(environment.services.foodDeliveryAreaService, {
      neighborhoodIds
    })
    .pipe(
      map((obj) => {
        return obj;
      })
    )
    .pipe(share());
  }

  savePaymentProfile(paymentProfile: PaymentProfile):
    Observable<PaymentProfile> {
    return this.http[paymentProfile.id ? 'put' : 'post']<PaymentProfileResponse>
    (environment.services.paymentProfileService +
      (paymentProfile.id ? paymentProfile.id : ''), paymentProfile)
    .pipe(
      map((obj) => {
        this.fps.track('AddPaymentInfo');
        return obj.object;
      })
    );
  }

  repeatOrder(code: string):
    Observable<ShoppingCart> {
    return this.http.get<ShoppingCart>(environment.services.repeatOrderService + '?code=' +
      code)
    .pipe(
      map((obj) => {
        return obj;
      })
    )
    .pipe(share());
  }
}
