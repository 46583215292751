<h1 mat-dialog-title>Aradığını bulamadın mı?</h1>
<div mat-dialog-content class="px-3">
  <p>Hemen whatsapp danışma hattımıza mesajını bırak!</p>
  <div class="d-flex justify-content-end flex-row">
    <a mat-dialog-close mat-button>Kapat</a>
    <a href="https://api.whatsapp.com/send?phone=905459034890"
       target="_blank"
       mat-raised-button color="primary">
      Konuşma Başlat
    </a>
  </div>
</div>
