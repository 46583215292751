import {
  transition,
  trigger,
  query,
  style,
  animate,
  group,
  animateChild, state, stagger, sequence, keyframes
} from '@angular/animations';

export const listAnimation =
  [
    trigger('fade', [
      transition(':enter', [
        style({opacity: 0}),
        animate('300ms', style({opacity: 1}))
      ]),
      transition(':leave', [
        style({opacity: 1}),
        animate('300ms', style({opacity: 0}))
      ]),
    ]),
    trigger('stagger', [
      transition(':enter', [
        query(':enter', stagger('.2s', [animateChild()]))
      ])
    ])
  ];


export const fadeAnimation2 = trigger('fadeAnimation', [
  transition('* => *', [
    query(
      ':enter',
      [style({opacity: 0})],
      {optional: true}
    ),
    query(
      ':leave',
      [style({opacity: 1}), animate('0.7s', style({opacity: 0}))],
      {optional: true}
    ),
    query(
      ':enter',
      [style({opacity: 0}), animate('0.7s', style({opacity: 1}))],
      {optional: true}
    )
  ])
]);

export const flyInOut = trigger('flyInOut', [
  state('in', style({transform: 'translateX(0)'})),
  transition('void => *', [
    animate(
      300,
      keyframes([
        style({opacity: 0, transform: 'translateX(-100%)', offset: 0}),
        style({opacity: 1, transform: 'translateX(15px)', offset: 0.3}),
        style({opacity: 1, transform: 'translateX(0)', offset: 1.0})
      ])
    )
  ]),
  transition('* => void', [
    animate(
      300,
      keyframes([
        style({opacity: 1, transform: 'translateX(0)', offset: 0}),
        style({opacity: 1, transform: 'translateX(-15px)', offset: 0.7}),
        style({opacity: 0, transform: 'translateX(100%)', offset: 1.0})
      ])
    )
  ])
]);

export const rowsAnimation =
  trigger('rowsAnimation', [
    transition('void => *', [
      style({height: '*', opacity: '0', transform: 'translateX(-550px)', 'box-shadow': 'none'}),
      sequence([
        animate('.35s ease', style({height: '*', opacity: '.2', transform: 'translateX(0)', 'box-shadow': 'none'})),
        animate('.35s ease', style({height: '*', opacity: 1, transform: 'translateX(0)'}))
      ])
    ])
  ]);

export const enterAnimation = trigger(
  'enterAnimation', [
    transition(':enter', [
      style({transform: 'translateX(100%)', opacity: 0}),
      animate('500ms', style({transform: 'translateY(0)', opacity: 1}))
    ]),
    transition(':leave', [
      style({transform: 'translateX(0)', opacity: 1}),
      animate('500ms', style({transform: 'translateY(100%)', opacity: 0}))
    ])
  ]
);
export const fadeAnimation = trigger(
  'fadeAnimation', [
    transition(':enter', [
      style({opacity: 0}),
      animate('300ms', style({opacity: 1}))
    ]),
    transition(':leave', [
      style({opacity: 1}),
      animate('300ms', style({opacity: 0}))
    ])
  ]
);

export const pageFadeAnimation =
  trigger('pageFadeAnimation', [
    transition('* => *', [

      query(':enter',
        [
          style({width: '100%', position: 'absolute', top: 0, opacity: 0.05})
        ],
        {optional: true}
      ),

      query(':leave',
        [
          style({opacity: 1}),
          animate('0.2s', style({opacity: 0.05}))
        ],
        {optional: true}
      ),

      query(':enter',
        [
          style({opacity: 0.05}),
          animate('0.2s', style({opacity: 1}))
        ],
        {optional: true}
      )

    ])
  ]);
