import {AfterViewInit, Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../../pages/authentication/authentication.service';
import {LayoutService} from '../../../layout.service';
import {ContentCategory, Module} from '../../../../interfaces/DataModel';
import {enterAnimation} from '../../../../animations/system.animation';
import {Location} from '@angular/common';
import {NavigationStart, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {HttpCancelService} from '../../../../services/http-cancel.service';
import {MatDialog} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ScopeService} from '../../../../services/scope.service';
import {ContentService} from '../../../../services/content.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    enterAnimation
  ],
})
export class SidebarComponent implements OnInit, OnDestroy {

  @HostBinding('class.open')
  isOpen: boolean = true;
  @HostBinding('class.locked-open')
  isLockOpen: boolean = true;
  menu: Module[] = [];
  // public version: string = environment.version;
  private destroySubject$: Subject<any> = new Subject<any>();
  contentCategories: ContentCategory[] = [];

  constructor(public authService: AuthenticationService,
              public router: Router,
              private contentService: ContentService,
              private httpCancelService: HttpCancelService,
              private location: Location,
              private translateService: TranslateService,
              private dialog: MatDialog,
              public scopeService: ScopeService,
              public layoutService: LayoutService) {
  }

  ngOnInit() {
    this.scopeService.menu
    .pipe(takeUntil(this.destroySubject$))
    .subscribe(menu => this.menu = menu);

    this.router.events
    .pipe(takeUntil(this.destroySubject$))
    .subscribe(events => {
      if (events instanceof NavigationStart) {
        this.layoutService.closeSidebar();
      }
    });
  }

  setTab(index: number) {
    if (index === 1) {
      this.contentService.getContentCategories({contentTypeKey: 'product'})
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((contentCategories) => {
        this.contentCategories = contentCategories;
      });
    }
  }

  login() {
    this.router.navigate(['/auth/login']);
  }

  ngOnDestroy(): void {
    this.destroySubject$.next();
  }
}
