<div mat-dialog-content class="p-0">
  <a *ngIf="imageUrl"
     class="d-block cursor-pointer"
     (click)="close(data.url)">
    <img [src]="imageUrl" alt="Promosyon" class="img-fluid"/>
  </a>
  <app-video-player *ngIf="videoUuid"
                    [autoPlay]="true"
                    [video]="{fileType: 'video', uuid: videoUuid}">
  </app-video-player>
  <button style="position:absolute; right: 10px; top:10px; font-size: 30px"
          aria-label="iptal"
          mat-icon-button [mat-dialog-close]>
    <mat-icon [inline]="true">cancel</mat-icon>
  </button>
</div>
<!--<div mat-dialog-actions class="px-3">
  <div fxFlex fxLayout="row" fxLayoutAlign="end end">
    <button aria-label="" mat-button [mat-dialog-close]>Kapat</button>
  </div>
</div>-->
