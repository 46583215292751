<div fxLayoutGap="10px"
     class="d-flex flex-row justify-content-between align-items-center">
  <div class="d-flex flex-grow-1">
    <div class="w-100 text-ellipsis overflow-hidden"
         [innerHTML]="data.html">
    </div>
  </div>
  <div class="d-flex flex-grow-0">
    <button mat-button
            aria-label="kapat"
            class="fs-12"
            (click)="snackBar.dismiss()"
            [innerHTML]="data.action">
    </button>
  </div>
</div>
