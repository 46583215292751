import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuickMenuComponent } from './quick-menu/quick-menu.component';
import {MatFabMenuModule} from '@angular-material-extensions/fab-menu';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FlexModule} from '@angular/flex-layout';
import {MatRippleModule} from '@angular/material/core';
import {CallMeModule} from '../call-me/call-me.module';
import {CallMeComponent} from '../call-me/call-me/call-me.component';
import {PipesModule} from '../../pipes/pipes.module';

@NgModule({
    declarations: [QuickMenuComponent],
    exports: [
        QuickMenuComponent,
        CallMeModule
    ],
    imports: [
        CommonModule,
        MatFabMenuModule,
        MatButtonModule,
        MatTooltipModule,
        FlexModule,
        MatRippleModule,
        CallMeModule,
        PipesModule
    ]
})
export class QuickMenuModule { }
