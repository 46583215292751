import {
  HttpInterceptor as HttpInterceptorModel,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class LangInterceptor implements HttpInterceptorModel {

  constructor(public translateService: TranslateService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request.clone({
      headers: request.headers.set('lang', `${this.translateService.currentLang ? this.translateService.currentLang : 'tr'}`)
    }));
  }
}
