import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../environments/environment';
import {Popup} from '../../interfaces/DataModel';
import {Router} from '@angular/router';

@Component({
  selector: 'app-promo-popup',
  templateUrl: './promo-popup.component.html',
  styleUrls: ['./promo-popup.component.scss']
})
export class PromoPopupComponent implements OnInit {

  imageUrl: string;
  videoUuid: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Popup,
              private router: Router,
              public dialogRef: MatDialogRef<PromoPopupComponent>) { }

  ngOnInit(): void {
    if (this.data?.imageUuid) {
      this.imageUrl = environment.services.fileService + this.data.imageUuid + '?width=600&height=600';
    }
    if (this.data?.videoUuid) {
      this.videoUuid = this.data.videoUuid;
    }
  }

  close(url: string = null) {
    this.dialogRef.close(url);
  }
}
