<div>
  <button mat-icon-button
          aria-label="Sepet"
          [mdePopoverTriggerFor]="appPopover"
          mdePopoverTriggerOn="click"
          [mdePopoverCloseOnClick]="false"
          [mdePopoverOverlapTrigger]="false"
          mdePopoverArrowWidth="10"
          mdePopoverPositionX="before"
          mdePopoverPositionY="below"
          mdePopoverArrowColor="white"
          #popoverTrigger="mdePopoverTrigger"
          color="accent">
    <mat-icon color="accent"
              [matBadge]="shoppingCartForm?.controls?.length.toString()"
              [matBadgeHidden]="shoppingCartForm?.controls.length === 0"
              matBadgeSize="small"
              matBadgeColor="primary">shopping_bag
    </mat-icon>
  </button>
</div>

<mde-popover #appPopover="mdePopover"
             class="shopping-cart-popover"
             [mdeFocusTrapEnabled]="false"
             [mdeFocusTrapAutoCaptureEnabled]="false"
             [mdePopoverOverlapTrigger]="false">
  <mat-card class="p-0">
    <mat-card-content>
      <!--<ngx-spinner bdColor="rgba(255,255,255,0.5)"
                   size="medium"
                   *ngIf="scopeService.loading$ | async"
                   color="rgba(185, 148, 98, .5)"
                   type="ball-triangle-path"
                   zIndex="999999999999"
                   [fullScreen]="false">
      </ngx-spinner>-->
      <div class="mini-shopping-cart-list d-flex flex-column"
           *ngIf="shoppingCartForm?.controls.length > 0; else emptyShoppingCart"
           [formGroup]="form">
        <div class="px-2 py-1" fxFlex fxLayout="row" fxLayoutAlign="space-between center">
          <h3>{{'shopping-cart.shopping-cart' | translate}}</h3>
          <a mat-icon-button (click)="closePopover()">
            <mat-icon>close</mat-icon>
          </a>
        </div>
        <div class="mini-scrollbar mini-shopping-cart">
          <div formArrayName="items">
            <div *ngFor="let shoppingCartItem of shoppingCartForm?.controls; let index = index"
                 [formGroupName]="index"
                 [id]="'item-' + shoppingCart?.shoppingCartItems[index].productItemId"
                 [ngClass]="{'one-blink-effect': shoppingCartService.lastAddedProductItemId === shoppingCart?.shoppingCartItems[index].productItemId}"
                 class="mini-shopping-cart-item">
              <div fxFlex
                   fxLayoutAlign="space-between center">
                <div fxFlex fxLayout="column">
                  <div>{{shoppingCart?.shoppingCartItems[index].productItem.product.name}}</div>
                  <div
                    *ngIf="shoppingCart?.shoppingCartItems[index].productItem.product.productGroup.groupKey === 'menu'">
                    <div class="text-black-50" fxFlex fxLayout="row wrap" fxLayoutGap="5px">
                      <div>{{shoppingCart?.shoppingCartItems[index].productItem.meta.day}} {{'product.day' | translate}}</div>
                      <div>-</div>
                      <div>{{shoppingCart?.shoppingCartItems[index].productItem.meta.calorie}} {{'product.calorie' | translate}}</div>
                    </div>
                  </div>
                  <div class="text-black-50"
                       *ngIf="shoppingCart?.shoppingCartItems[index].productItem.product.productGroup.groupKey === 'dietitian_counseling'">
                    <strong
                      class="fw-500">{{shoppingCart?.shoppingCartItems[index].productItem.dietitian.fullName}}</strong>
                    <span> - {{'consulting_type.' + shoppingCart?.shoppingCartItems[index].productItem.meta.consultingType | translate}}</span>
                  </div>
                  <div class="mt-2">
                    <div class="sale-price fw-600"
                         *ngIf="shoppingCart?.shoppingCartItems[index].salePrice"
                         [innerHTML]="scopeService.priceFormatter(shoppingCart?.shoppingCartItems[index].salePrice, true)">
                    </div>
                    <div class="price fs-16 fw-600" [innerHTML]="scopeService.priceFormatter(shoppingCart?.shoppingCartItems[index].price, true)"></div>
                  </div>
                </div>
                <div fxFlex="0 0 140px" fxLayout="row">
                  <div class="d-flex flex-row"
                       *ngIf="shoppingCart?.shoppingCartItems[index].productItem.quantitySelectable &&
                       shoppingCart?.shoppingCartItems[index].productItem.maxQuantity !== 1"
                       fxLayoutAlign="space-between center">
                    <button mat-icon-button
                            aria-label="adet sil"
                            (click)="removeQuantity(shoppingCart?.shoppingCartItems[index])">
                      <mat-icon>remove</mat-icon>
                    </button>
                    <mat-form-field appearance="outline"
                                    class="quantity-input no-padding-input auto-width-input">
                      <mat-label>{{'shopping-cart.quantity' | translate}}</mat-label>
                      <input matInput
                             readonly
                             class="text-center"
                             cdkfocusinitial
                             formControlName="quantity"
                             autocomplete="off"
                             [placeholder]="'shopping-cart.quantity' | translate">
                    </mat-form-field>
                    <button mat-icon-button
                            aria-label="adet ekle"
                            [disabled]="shoppingCartItem.value === 1"
                            (click)="addQuantity(shoppingCart?.shoppingCartItems[index])">
                      <mat-icon>add</mat-icon>
                    </button>
                  </div>
                </div>
                <div fxFlex="none">
                  <a mat-icon-button
                     [appConfirm]="removeShoppingCartProductConfirm"
                     (status)="removeShoppingCart($event, shoppingCartItem.value.id)">
                    <mat-icon color="accent">remove_shopping_cart</mat-icon>
                  </a>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column coupon-codes mini-shopping-cart">
              <div *ngFor="let couponCodeItem of couponCodes"
                   class="d-flex flex-row mini-shopping-cart-item"
                   fxLayoutGap="10px"
                   fxLayoutAlign="space-between center">
                <div fxFlex fxLayout="column">
                  <span class="fs-14 fw-500">{{couponCodeItem?.couponCode}}</span>
                  <span class="fs-12 text-black-50"
                        *ngIf="couponCodeItem.coupon?.description">{{couponCodeItem.coupon?.description}}</span>
                </div>
                <div fxFlex="initial"
                     class="primary-color fw-500"
                     [innerHTML]="scopeService.priceFormatter(couponCodeItem.price)">
                </div>
                <div fxFlex="initial">
                  <a mat-icon-button
                     [appConfirm]="removeShoppingCartCouponConfirm"
                     (status)="removeShoppingCart($event, couponCodeItem.id)">
                    <mat-icon color="accent">close</mat-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="price-box d-flex flex-column justify-content-end align-items-end">
            <div *ngIf="shoppingCart?.priceExcludedTax"
                 class="d-flex flex-row"
                 fxLayoutGap="5px">
              <span>{{'shopping-cart.subtotal' | translate}}: </span>
              <strong
                [innerHTML]="scopeService.priceFormatter(shoppingCart?.priceExcludedTax)"></strong>
            </div>
            <div *ngIf="shoppingCart?.taxAmount"
                 class="d-flex flex-row"
                 fxLayoutGap="5px">
              <span>{{'shopping-cart.tax' | translate}}: </span>
              <strong
                [innerHTML]="scopeService.priceFormatter(shoppingCart?.taxAmount)"></strong>
            </div>
            <div *ngIf="shoppingCart?.discountTotalFormatted"
                 class="d-flex flex-row"
                 fxLayoutGap="5px">
              <span>{{'shopping-cart.discount_total' | translate}}: </span>
              <strong
                [innerHTML]="scopeService.priceFormatter(shoppingCart?.discountTotalFormatted)">
              </strong>
            </div>
            <div *ngIf="shoppingCart?.price != null"
                 class="d-flex flex-row"
                 fxLayoutGap="5px">
              <span>{{'shopping-cart.total' | translate}}: </span>
              <strong class="price"
                      [innerHTML]="scopeService.priceFormatter(shoppingCart?.priceFormatted)"></strong>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row px-3" fxLayoutAlign="space-between end">
          <button mat-raised-button
                  color="accent"
                  aria-label="kupon ekle"
                  class="me-3 ms-0"
                  (click)="openDialogWithRef(couponDialog)">
            {{'shopping-cart.enter_coupon_code' | translate}}
          </button>
          <a mat-flat-button
             class="me-0"
             (click)="goToPayment()"
             [disabled]="shoppingCartForm?.controls.length === 0"
             color="primary">
            {{'shopping-cart.complete_order' | translate}}
            <mat-icon>keyboard_arrow_right</mat-icon>
          </a>
        </div>
      </div>
      <ng-template #emptyShoppingCart>
        <div class="px-5 py-3">Sepetiniz boş</div>
      </ng-template>

      <!--<div fxFlex
           fxLayout="column"
           fxLayoutGap="5px">
        <button mat-stroked-button color="warn" fxFlex (click)="ShoppingCart(true)">
          {{'shopping-cart.empty-and-buy' | translate}}
        </button>
        <button mat-stroked-button color="primary" fxFlex (click)="ShoppingCart()">
          {{'shopping-cart.add-and-buy' | translate}}
        </button>
      </div>-->
    </mat-card-content>
  </mat-card>
</mde-popover>

<ng-template #couponDialog>
  <!--<ngx-spinner bdColor="rgba(255,255,255,0.5)"
               size="medium"
               *ngIf="scopeService.loading$ | async"
               color="rgba(185, 148, 98, .5)"
               type="ball-triangle-path"
               zIndex="999999999999"
               [fullScreen]="false">
  </ngx-spinner>-->

  <h1 matDialogTitle>{{'shopping-cart.enter_coupon_code' | translate}}</h1>
  <mat-dialog-content class="px-3 my-2">
    <div fxFlex fxLayout="row" fxLayoutGap="5px">
      <mat-form-field appearance="outline"
                      fxFlex
                      class="no-padding-input auto-width-input">
        <mat-label>{{'shopping-cart.enter_coupon_code' | translate}}</mat-label>
        <input matInput
               autocomplete="off"
               required
               [(ngModel)]="couponCode"
               (keydown.enter)="setCouponCode()"
               cdkfocusinitial
               [ngModelOptions]="{ standalone: true }"
               [placeholder]="'shopping-cart.your_code' | translate">
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button
            aria-label="iptal"
            (click)="couponCodeDialog.close()"
            [disabled]="scopeService.loading$ | async"
            color="primary">
      {{'shopping-cart.cancel' | translate}}
    </button>
    <a mat-raised-button
       color="primary"
       [disabled]="!couponCode || (scopeService.loading$ | async)"
       (click)="setCouponCode()">
      {{'shopping-cart.add' | translate}}
    </a>
  </mat-dialog-actions>
</ng-template>
