<h1 mat-dialog-title class="w-100 d-flex align-items-center justify-content-between">
  <span>Sizi arayalım</span>
  <button mat-icon-button mat-dialog-close aria-label="kapat">
    <mat-icon>close</mat-icon>
  </button>
</h1>
<div mat-dialog-content class="px-3 py-3">
  <ngx-spinner bdColor="rgba(255,255,255,0.5)"
               size="medium"
               *ngIf="scopeService.loading$ | async"
               color="rgba(185, 148, 98, .5)"
               type="ball-triangle-path"
               zIndex="999999999999"
               [fullScreen]="false">
  </ngx-spinner>

  <ng-container *ngIf="authService.loggedIn && !changePhoneForm && authService.auth?.profile?.phone; else ghost">
    Sayın <strong>{{authService.auth.profile.fullName}}</strong> sizi
    <strong>{{addressPhoneFormatted?.formatNational()}}</strong>
    numaralı telefonunuzdan mı arayalım ?

    <form [formGroup]="form"
          class="mt-3 d-flex justify-content-end"
          (ngSubmit)="send()">
      <mat-radio-group formControlName="request"
                       aria-label="Arama sebebi seçin">
        <mat-radio-button value="Bilgi hattı" class="me-2">Bilgi hattı</mat-radio-button>
        <mat-radio-button value="Diyetisyen desteği">Diyetisyen desteği</mat-radio-button>
      </mat-radio-group>
    </form>
  </ng-container>
  <ng-template #ghost>
    <form [formGroup]="form"
          (ngSubmit)="send()"
          class="d-flex flex-column">
      <div fxFlex="100%"
           fxLayout="row"
           fxLayoutGap="10px">
        <mat-form-field class="auto-width-input"
                        fxFlex="50%"
                        appearance="outline">
          <mat-label>{{'account.first_name' | translate}}</mat-label>
          <input matInput
                 required
                 formControlName="firstName"
                 autocomplete="off"
                 [placeholder]="'account.first_name' | translate">
        </mat-form-field>
        <mat-form-field class="auto-width-input"
                        fxFlex="50%"
                        appearance="outline">
          <mat-label>{{'account.last_name' | translate}}</mat-label>
          <input matInput
                 required
                 formControlName="lastName"
                 autocomplete="off"
                 [placeholder]="'account.last_name' | translate">
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline" fxFlex>
        <mat-label>{{'account.phone' | translate}}</mat-label>
        <ngx-mat-intl-tel-input
          [preferredCountries]="['tr']"
          [enablePlaceholder]="true"
          [enableSearch]="false"
          [placeholder]="'account.phone' | translate"
          name="phone"
          formControlName="phone">
        </ngx-mat-intl-tel-input>
        <div matSuffix class="ps-2">
          <mat-icon>phone</mat-icon>
        </div>
      </mat-form-field>

      <mat-radio-group class="mb-2" formControlName="request" aria-label="Arama sebebi seçin">
        <mat-radio-button value="Bilgi hattı" class="me-2">Bilgi hattı</mat-radio-button>
        <mat-radio-button value="Diyetisyen desteği">Diyetisyen desteği</mat-radio-button>
      </mat-radio-group>

      <mat-checkbox *ngIf="authService.loggedIn" color="primary" formControlName="saveMyPhone">
        <div class="fs-11">İletişim numaram olarak kaydet</div>
      </mat-checkbox>

      <mat-checkbox color="primary" formControlName="approve">
        <div class="fs-11"><a routerLink="/pages/kvkk" mat-dialog-close>KVKK aydınlatma metnini</a> okudum, onaylıyorum.</div>
      </mat-checkbox>
    </form>
  </ng-template>
</div>
<div mat-dialog-actions>
  <div class="w-100 d-flex align-items-center justify-content-end">
    <button *ngIf="authService.loggedIn && !changePhoneForm && authService.auth?.profile?.phone"
            mat-button
            aria-label="numara değiştir"
            (click)="changePhone()">
      Numara Değiştir
    </button>
    <button mat-flat-button
            aria-label="gönder"
            color="primary"
            [disabled]="form.pending || form.invalid || (scopeService.loading$ | async)"
            (click)="send()">
      <ng-container *ngIf="authService.loggedIn && !changePhoneForm && authService.auth?.profile?.phone; else ghostBtnText">Evet, Lütfen</ng-container>
      <ng-template #ghostBtnText>Gönder</ng-template>
    </button>
  </div>
</div>
