import {Inject, Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {catchError, map, share} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {SystemResponse} from '../interfaces/Responses';
import {System} from '../interfaces/DataModel';
import {LocalStorageService} from './local-storage.service';
import moment from 'moment';
import {ScopeService} from './scope.service';
import {ConfirmDialogComponent} from '../dialogs/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {NgConnection} from 'ng-connection';
import {PromoPopupComponent} from '../dialogs/promo-popup/promo-popup.component';
import {ActivatedRoute, Router} from '@angular/router';
import {SwUpdate} from '@angular/service-worker';
import {Observable, of, throwError} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {AuthenticationService} from '../pages/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  dialogOpen: boolean = false;
  isConnected: boolean = true;

  constructor(private http: HttpClient,
              private scopeService: ScopeService,
              private authService: AuthenticationService,
              private dialog: MatDialog,
              private connection: NgConnection,
              private router: Router,
              private route: ActivatedRoute,
              private swUpdate: SwUpdate,
              @Inject(DOCUMENT) private document: Document,
              private localStorage: LocalStorageService) {
    this.connection.connectivity(true)
    .subscribe(isConnected => {
      this.isConnected = isConnected;
    });
  }

  system(first: boolean = false):
    Promise<System> {
    return this.http.get<SystemResponse>(environment.services.systemService)
    .pipe(
      map((obj) => {
        return this.process(obj.object, first);
      })
    )
    .pipe(share())
    .toPromise();
  }

  process(system: System, first: boolean = false) {
    this.scopeService.menu.next(
      this.scopeService.menu.getValue().map(menuItem => {
        if (menuItem.key === 'campaigns') {
          menuItem.disabled = !system.campaigns;
        }
        return menuItem;
      })
    );

    const session = this.localStorage.getItem('system.session');
    if (session) {
      system.session = session;
    }

    let returnObject: System;

    /*if (this.swUpdate.isEnabled) {
      this.swUpdate.activateUpdate().then(() => {
        this.dialogOpen = true;
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          panelClass: 'no-padding-dialog',
          width: '250px',
          data: {
            title: 'Bilgi!',
            description: 'Yeni bir versiyon yüklendi, güncellemeyi almak için lütfen sayfayı yenileyiniz.',
            okButtonText: 'Yenile',
            cancelButtonText: 'Daha Sonra',
          },
        });
        dialogRef.afterClosed()
          .subscribe(result => {
            this.dialogOpen = false;

            if (result) {
              this.swUpdate.activateUpdate().then(() => this.document.location.reload());
            }
          });
      });
    }*/

    if (!first) {
      const backendVersion = this.localStorage.getItem('system.backendVersion');
      if (backendVersion !== system.backendVersion && !this.dialogOpen) {
        this.dialogOpen = true;
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          panelClass: 'no-padding-dialog',
          width: '250px',
          data: {
            title: 'Bilgi!',
            description: 'Yeni bir versiyon yüklendi, güncellemeyi almak için lütfen sayfayı yenileyiniz.',
            okButtonText: 'Yenile',
            cancelButtonText: 'Daha Sonra',
          },
        });
        dialogRef.afterClosed()
        .subscribe(result => {
          this.dialogOpen = false;

          if (result) {
            window.location.reload();
            this.localStorage.setItem('system', system);
            returnObject = system;
          } else {
            system.backendVersion = backendVersion;
            this.localStorage.setItem('system', system);
            returnObject = system;
          }
        });
      } else {
        this.localStorage.setItem('system', system);
        returnObject = system;
      }
    } else {
      this.localStorage.setItem('system', system);
      returnObject = system;
    }

    let popupVersion;
    let popupData;

    // console.log(window.location.pathname);

    if (window.location.pathname.indexOf('/programs') > -1) {
      popupVersion = 'system.eattkitPopup.version';
      popupData = this.localStorage.getItem('system.eattkitPopup');
    } else {
      popupVersion = 'system.popup.version';
      popupData = this.localStorage.getItem('system.popup');
    }

    /*if (window.location.hostname === '90fit.com.tr') {
      popupVersion = 'system.popup.version';
      popupData = this.localStorage.getItem('system.popup');
    } else if (window.location.hostname === 'eattkit.com') {
      popupVersion = 'system.eattkitPopup.version';
      popupData = this.localStorage.getItem('system.eattkitPopup');
    } else if (window.location.hostname === 'localhost') {
      popupVersion = 'system.eattkitPopup.version';
      popupData = this.localStorage.getItem('system.eattkitPopup');
    }*/

    const currentVersion = this.localStorage.getItem(popupVersion);
    const userVersion = this.localStorage.getItem('popup_version');

    if (currentVersion &&
      !this.dialogOpen &&
      userVersion !== currentVersion) {
      this.dialogOpen = true;
      const promoPopupDialog = this.dialog.open(PromoPopupComponent, {
        panelClass: ['no-padding-dialog', 'promo-popup'],
        width: '500px',
        disableClose: true,
        autoFocus: false,
        role: 'dialog',
        data: popupData,
      });
      promoPopupDialog.afterClosed().subscribe((url) => {
        this.dialogOpen = false;
        this.localStorage.setItem('popup_version', currentVersion);
        if (url) {
          window.location.href = url;
          // this.router.navigateByUrl(url).then();
        }
      });
    }

    return returnObject;
  }

  utmControl(queryParams: { [key: string]: string }): Observable<any> {
    // utm_campaign=Q3_2021_Gantek_Webinar
    // utm_source=instagram
    // utm_medium=paidsocial
    // utm_content=remarketing-single2

    if (Object.keys(queryParams).length === 0) {
      return of();
    }

    const filteredQueryParams = Object.keys(queryParams).filter(key => {
      return key.indexOf('utm_') === 0;
    });

    if (Object.keys(filteredQueryParams).length === 0) {
      return of();
    }

    const newQueryParams = {};

    Object.keys(queryParams).map(key => {
      if (filteredQueryParams.some(k => k === key)) {
        newQueryParams[key] =  queryParams[key];
      }
    });

    this.scopeService.showLoading = false;
    return this.http
    .post<{object: {utmId: string | null}}>
        (environment.services.adsCampaignService + 'getCampaign', newQueryParams)
    .pipe(
      map((obj) => {
        if (obj.object.utmId && !this.authService.loggedIn) {
          this.localStorage.setItem('utmId', obj.object.utmId);
        }
        return obj.object.utmId;
      })
    )
    .pipe(share());
  }
}
