import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  public storageSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() {
  }

  setItem(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));
    this.storageSubject.next(null);
  }

  getItem(key: string) {
    try {
      let data;

      if (key.indexOf('.') > -1) {
        const keys = key.split('.');
        keys.map((k, index) => {
          if (index === 0) {
            data = JSON.parse(localStorage.getItem(k));
          } else {
            data = data[k];
          }
        });
      } else {
        data = JSON.parse(localStorage.getItem(key));
      }

      return data;
    } catch (e) {
      return localStorage.getItem(key);
    }
  }

  removeItem(key: string) {
    try {
      const remove = localStorage.removeItem(key);
      this.storageSubject.next(null);
      return remove;
    } catch (e) {
    }
  }
}
