import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, enableProdMode, ErrorHandler, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {LanguageLoader} from './extends/LanguageLoader';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './interceptors/AuthInterceptor';
import {HttpCancelInterceptor} from './interceptors/HttpCancelInterceptor';
import {HttpInterceptor} from './interceptors/HttpInterceptor';
import {LangInterceptor} from './interceptors/LangInterceptor';
import {ErrorException} from './exeptions/ErrorException';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DialogsModule} from './dialogs/dialogs.module';
import {MatDialogModule} from '@angular/material/dialog';
import {PermissionGuard} from './guards/permission.guard';
import {LocalStorageService} from './services/local-storage.service';
import {DirectivesModule} from './directives/directives.module';
import 'moment/locale/tr';
import {BaseComponent} from './extends/BaseComponent';
import {SWIPER_CONFIG, SwiperConfigInterface, SwiperModule} from 'ngx-swiper-wrapper';
import {MatFabMenuModule} from '@angular-material-extensions/fab-menu';
import {DefaultLayoutModule} from './layouts/default-layout/default-layout.module';
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {environment} from '../environments/environment';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {AppInitService} from './services/app-init.service';
import {ShoppingCartModule} from './modules/shopping-cart/shopping-cart.module';
import {ShoppingCartService} from './modules/shopping-cart/shopping-cart.service';
import {OrderService} from './pages/account/orders/order.service';
import {MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import {IConfig, NgxMaskModule} from 'ngx-mask';
import {NotificationService} from './services/notification.service';
import {SocketService} from './services/socket.service';
import {ServiceWorkerModule} from '@angular/service-worker';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {SnackBarModule} from './modules/snack-bar/snack-bar.module';
import {ShoppingCartInterceptor} from './interceptors/ShoppingCartInterceptor';
import {ContentService} from './services/content.service';
import {JsonLdModule} from 'ngx-seo';
import {LAZYLOAD_IMAGE_HOOKS, ScrollHooks} from 'ng-lazyload-image';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {JoyrideModule} from 'ngx-joyride';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {FileService} from './services/file.service';
// import {Ng9OdometerModule} from 'ng9-odometer';
import {ShareButtonsPopupModule} from 'ngx-sharebuttons/popup';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {ShareButtonsConfig} from 'ngx-sharebuttons/lib/share.models';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {ShareIconsModule} from 'ngx-sharebuttons/icons';

declare var OneSignal;

(window as any).global = window;
declare var require: any;

declare global {
  interface Window {
    io: any;
  }

  interface Window {
    Echo: any;
  }

  interface Window {
    Odometer: any;
  }
}

window.io = window.io || require('socket.io-client');
window.Echo = window.Echo || {};

if (environment.production) {
  enableProdMode();
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'LL dddd',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

export function initializeApp1(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.system(true);
  };
}

const maskConfig: Partial<IConfig> = {
  validation: false,
};

if (environment.production) {
  // @ts-ignore
  /*window.OneSignal = window.OneSignal || [];
  OneSignal.push(() => {
    OneSignal.init({
      appId: '93d0ed76-cc5d-4c53-9aa7-ecd54a116e45',
      allowLocalhostAsSecureOrigin: true,
      notifyButton: {
        enabled: true,
      },
      webhooks: {
        cors: false,
        'notification.displayed': environment.services.pushNotificationService,
        'notification.clicked': environment.services.pushNotificationService,
        'notification.dismissed': environment.services.pushNotificationService,
      }
    });
  });*/
}

export const shareButtonsConfig: ShareButtonsConfig = {
  include: ['facebook', 'whatsapp', 'sms', 'email'],
  theme: 'modern-light',
  size: 7,
  url: 'https://90fit.com.tr',
  title: 'Adrese Teslim Diyet Yemek Servisi Hizmeti | 90Fit',
  description: 'Adrese teslim diyet yemek servisi hizmeti ile her sabah taze pişirilip paketlenmiş günlük diyet yemeğini adresinizde teslim alırsınız.',
  image: 'https://90fit.com.tr/assets/icons/favicon/apple-icon-precomposed.png',
  tags: 'adrese teslim yemek, sağlıklı beslenme, diyet yemek, diyetisyen danışmanlığı'
};

// @ts-ignore
@NgModule({
  declarations: [
    AppComponent,
    BaseComponent,
    NotFoundComponent,
  ],
  imports: [
    NgxMaskModule.forRoot(maskConfig),
    HttpClientModule,
    LoadingBarHttpClientModule,
    LoadingBarModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    FlexLayoutModule,
    MatDialogModule,
    DialogsModule,
    MatSnackBarModule,
    SwiperModule,
    SnackBarModule,
    JsonLdModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: LanguageLoader
      }
    }),
    MatFabMenuModule,
    DefaultLayoutModule,
    MatProgressSpinnerModule,
    ShoppingCartModule,

    // Ng9OdometerModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    JoyrideModule.forRoot(),

    NgxWebstorageModule.forRoot({
      prefix: '',
      separator: '',
      caseSensitive: true
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    NgxSpinnerModule,

    ShareButtonsPopupModule.withConfig(shareButtonsConfig),
    ShareIconsModule,
  ],
  exports: [
    AppComponent,
    DirectivesModule,
    DialogsModule,
  ],
  providers: [
    OrderService,
    ShoppingCartService,
    LocalStorageService,
    PermissionGuard,
    NotificationService,
    SocketService,
    ContentService,

    AppInitService,
    FileService,

    // {provide: APP_BASE_HREF, useValue: environment.baseHref },
    {provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks},
    {provide: APP_INITIALIZER, useFactory: initializeApp1, deps: [AppInitService], multi: true},

    {provide: MatBottomSheetRef, useValue: {}},
    {provide: MAT_BOTTOM_SHEET_DATA, useValue: {}},

    // MAT DATEPICKER
    {provide: MAT_DATE_LOCALE, useValue: 'tr-TR'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},

    {
      provide: ErrorHandler,
      useClass: ErrorException
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LangInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ShoppingCartInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCancelInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 2000,
        verticalPosition: 'top'
      }
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(iconLibrary: FaIconLibrary) {
    // iconLibrary.addIconPacks(fas);
  }
}
