import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmDirective} from './confirm.directive';
import {PermissionDirective} from './permission.directive';
import { MatchHeightDirective } from './match-height.directive';


@NgModule({
  declarations: [
    ConfirmDirective,
    PermissionDirective,
    MatchHeightDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ConfirmDirective,
    PermissionDirective,
    MatchHeightDirective,
  ],
})
export class DirectivesModule {
}
