import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {ConfirmDialog} from '../interfaces/DataModel';
import {ConfirmDialogComponent} from '../dialogs/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Directive({
  selector: '[appConfirm]'
})
export class ConfirmDirective {

  @Input() appConfirm: ConfirmDialog = {};
  @Output() status = new EventEmitter();
  @Input() disabled: boolean = false;

  constructor(public dialog: MatDialog) {
  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();

    if (! this.disabled) {
      this.confirm(this.appConfirm)
      .then((status) => {
        this.status.emit(status);
      });
    }
  }

  confirm(data: ConfirmDialog = {}, width: string = '280px') {
    return new Promise((resolve) => {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        panelClass: 'no-padding-dialog',
        width,
        data,
      });
      dialogRef.afterClosed().subscribe(result => {
        resolve(result);
      });
    });
  }
}
