import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {map, share} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {NewsletterStoreRequest} from '../interfaces/request/NewsletterRequest';
import {NewsletterStoreResponse} from '../interfaces/response/NewsletterResponse';
import {
  Address, CallMe, CartStatus,
  City,
  Country,
  CreditCard,
  District, Invitation, Like,
  Neighborhood,
  Newsletter,
  Order, PagingObject,
  PaymentProfile,
  Town,
  User, UserProgram
} from '../interfaces/DataModel';
import {BehaviorSubject, Observable} from 'rxjs';
import {ProfileUpdateResponse} from '../interfaces/response/ProfileUpdateResponse';
import {
  AddressesResponse, AddressResponse,
  CitiesResponse,
  CountriesResponse, CreditCardResponse, CreditCardsResponse, CreditCardStatusResponse,
  DistrictsResponse, InvitationsResponse, LikesResponse,
  NeighborhoodResponse, PaymentProfilesResponse,
  TownsResponse, UserProgramResponse, UserProgramsResponse
} from '../interfaces/Responses';
import {Utility} from '../helpers/Utility';
import {LocalStorageService} from './local-storage.service';
import {AuthenticationService} from '../pages/authentication/authentication.service';
import {FacebookPixelService} from './facebook-pixel.service';

@Injectable({
  providedIn: 'root'
})
export class MyService {

  addressPagination: PagingObject = {
    currentPage: 1,
  };
  addresses$: BehaviorSubject<Address[]> = new BehaviorSubject<Address[]>(null);
  likes$: BehaviorSubject<LikesResponse> = new BehaviorSubject<LikesResponse>(null);

  constructor(private http: HttpClient,
              private authService: AuthenticationService,
              private fps: FacebookPixelService,
              private localStorage: LocalStorageService) {
  }

  accounts() {
    return this.http.get(environment.services.myService + 'accounts')
    .pipe(
      map((obj) => {
        return obj;
      })
    ).pipe(share());
  }

  eatRecipeCount() {
    return this.http.get<{object: { count: number, testCount: number }}>(environment.services.eatRecipeService + 'eatRecipeCount')
    .pipe(
      map((obj) => {
        return obj.object;
      })
    ).pipe(share());
  }

  saveMeta(data: any) {
    return this.http.post(environment.services.myService + 'saveMeta', data)
    .pipe(
      map((obj) => {
        return obj;
      })
    ).pipe(share());
  }

  paymentProfiles():
    Observable<PaymentProfile[]> {
    return this.http.get<PaymentProfilesResponse>(environment.services.myService + 'paymentProfiles')
    .pipe(
      map((obj) => {
        return obj.list;
      })
    ).pipe(share());
  }

  addFriendsInvitation(friends: any):
    Observable<any> {
    return this.http.post(environment.services.friendsInvitationService, friends)
    .pipe(
      map((obj) => {
        return obj;
      })
    ).pipe(share());
  }

  cancelFriendsInvitation(invitation: Invitation):
    Observable<any> {
    return this.http.delete(environment.services.friendsInvitationService + invitation.id)
    .pipe(
      map((obj) => {
        return obj;
      })
    ).pipe(share());
  }

  getFriendsInvitation():
    Observable<Invitation[]> {
    return this.http.get<InvitationsResponse>(environment.services.friendsInvitationService)
    .pipe(
      map((obj) => {
        return obj.list;
      })
    ).pipe(share());
  }

  removePaymentProfile(id: number) {
    return this.http.delete<PaymentProfilesResponse>(environment.services.paymentProfileService + id)
    .pipe(
      map((obj) => {
        return obj.list;
      })
    ).pipe(share());
  }

  addNewsletter(newsletterStoreRequest: NewsletterStoreRequest):
    Observable<Newsletter> {
    return this.http.post<NewsletterStoreResponse>(environment.services.newsletterService, newsletterStoreRequest)
    .pipe(
      map((obj) => {
        return obj.object;
      })
    );
  }

  callMe(callMe: CallMe):
    Observable<any> {
    return this.http.post<any>(environment.services.myService + 'callMe', callMe)
    .pipe(
      map((obj) => {
        if (this.localStorage.getItem('auth') && callMe.saveMyPhone) {
          const system = Object.assign({}, this.localStorage.getItem('auth'), {
            profile: obj.object
          });
          this.localStorage.setItem('auth', system);
        }
        this.fps.track('Lead');
        return obj.object;
      })
    );
  }

  updateProfile(user: User):
    Observable<User> {
    return this.http.post<ProfileUpdateResponse>(environment.services.myService + 'updateProfile', user)
    .pipe(
      map((obj) => {
        const system = Object.assign({}, this.localStorage.getItem('auth'), {
          profile: obj.object
        });
        this.localStorage.setItem('auth', system);

        return obj.object;
      })
    );
  }

  profile():
    Observable<User> {
    return this.http.get<ProfileUpdateResponse>(environment.services.myService + 'profile')
    .pipe(
      map((obj) => {
        return obj.object;
      })
    );
  }

  addresses(setAddresses: boolean = true,
            appendOrReset: 'append' | 'reset' = 'reset'):
    Observable<Address[]> {

    return this.http.get<AddressesResponse>(environment.services.addressService, {
      params: Utility.revert({
        page: this.addressPagination.currentPage,
      })
    }).pipe(
      map((obj) => {
        this.addressPagination = obj.object;

        if (setAddresses) {
          if (appendOrReset === 'reset') {
            this.addresses$.next(obj.list);
          } else {
            const addresses = this.addresses$.getValue();
            obj.list.map(x => {
              if (!addresses.find(y => y.uuid === x.uuid)) {
                addresses.push(x);
              }
            });
            this.addresses$.next(addresses);
          }
        }
        return this.addresses$.getValue();
      })
    ).pipe(share());
  }

  countries(query: { search?: string; size?: number, add?: any, delivery: boolean }):
    Observable<Country[]> {
    if (query.hasOwnProperty('add') && query.add.length > 0) {
      query.add = query.add.join(',');
    }
    return this.http.get<CountriesResponse>(environment.services.countryService, {
      params: Utility.revert(query)
    })
    .pipe(
      map((obj) => {
        return obj.list;
      })
    );
  }

  cities(query: { search?: string; size?: number, countryId: number, add?: any, delivery: boolean }):
    Observable<City[]> {
    if (query.hasOwnProperty('add') && query.add.length > 0) {
      query.add = query.add.join(',');
    }
    return this.http.get<CitiesResponse>(environment.services.cityService, {
      params: Utility.revert(query)
    })
    .pipe(
      map((obj) => {
        return obj.list;
      })
    );
  }

  districts(query: { search?: string; size?: number, townId: number, add?: any, delivery: boolean }):
    Observable<District[]> {
    if (query.hasOwnProperty('add') && query.add.length > 0) {
      query.add = query.add.join(',');
    }
    return this.http.get<DistrictsResponse>(environment.services.districtService, {
      params: Utility.revert(query)
    })
    .pipe(
      map((obj) => {
        return obj.list;
      })
    );
  }

  towns(query: { search?: string; size?: number, cityId: number, add?: any, delivery: boolean }):
    Observable<Town[]> {
    if (query.hasOwnProperty('add') && query.add.length > 0) {
      query.add = query.add.join(',');
    }
    return this.http.get<TownsResponse>(environment.services.townService, {
      params: Utility.revert(query)
    })
    .pipe(
      map((obj) => {
        return obj.list;
      })
    );
  }

  neighborhoods(query: { search?: string; size?: number, districtId: number, add?: any, delivery: boolean }):
    Observable<Neighborhood[]> {
    if (query.hasOwnProperty('add') && query.add.length > 0) {
      query.add = query.add.join(',');
    }
    return this.http.get<NeighborhoodResponse>(environment.services.neighborhoodService, {
      params: Utility.revert(query)
    })
    .pipe(
      map((obj) => {
        return obj.list;
      })
    );
  }

  neighborhoodsEasySearch(query: { search?: string; size?: number, add?: any, delivery: boolean }):
    Observable<Neighborhood[]> {
    if (query.hasOwnProperty('add') && query.add.length > 0) {
      query.add = query.add.join(',');
    }
    return this.http.get<NeighborhoodResponse>(environment.services.neighborhoodService + 'easySearch', {
      params: Utility.revert(query)
    })
    .pipe(
      map((obj) => {
        return obj.list;
      })
    );
  }

  saveAddress(address: Address):
    Observable<Address> {
    return this.http[address.uuid ? 'put' : 'post']
      < AddressResponse > (environment.services.addressService + (address.uuid ? address.uuid : ''), address)
      .pipe(
        map((obj) => {
          return obj.object;
        })
      );
  }

  creditCards():
    Observable<CreditCard[]> {
    return this.http.get<CreditCardsResponse>(environment.services.creditCardService)
    .pipe(
      map((obj) => {
        return obj.list;
      })
    );
  }

  removeCreditCard(creditCard: CreditCard) {
    return this.http.post<CreditCardsResponse>(environment.services.creditCardService + 'destroy', {
      cardToken: creditCard.cardToken,
    })
    .pipe(
      map((obj) => {
        return obj.list;
      })
    );
  }

  addCreditCard(creditCard: CreditCard) {
    return this.http.post<CreditCardResponse>(environment.services.creditCardService, creditCard)
    .pipe(
      map((obj) => {
        return obj.object;
      })
    );
  }

  deleteAddress(address: Address) {
    return this.http.delete(environment.services.addressService + address.uuid)
    .pipe(
      map((obj) => {
        return obj;
      })
    );
  }

  creditCardStatus(creditCardStatusRequest: { binNumber: string, price: number }):
    Observable<CartStatus> {
    return this.http.post<CreditCardStatusResponse>
    (environment.services.creditCardService + 'status', creditCardStatusRequest)
    .pipe(
      map((obj) => {
        return obj.object;
      })
    )
    .pipe(share());
  }

  setOneSignalId(oneSignal: { oneSignalUuid: string }):
    Observable<string[]> {
    return this.http.post<{ list: string[] }>(environment.services.oneSignalService, oneSignal)
    .pipe(
      map((obj) => {
        return obj.list;
      })
    );
  }
}
