import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Address, Author, Blog, ContentCategory, Menu, PagingObject, Product, Comment, GlobalAsset} from '../interfaces/DataModel';
import {environment} from '../../environments/environment';
import {map, share} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {
  AuthorsResponse,
  BlogResponse,
  BlogsResponse,
  ContentCategoriesResponse,
  ProductResponse,
  ProductsResponse
} from '../interfaces/response/ProductsResponse';
import {Utility} from '../helpers/Utility';
import {CommentResponse, CommentsResponse, GlobalAssetResponse, MenuResponse} from '../interfaces/Responses';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  blogPagination: PagingObject = {
    currentPage: 1,
  };
  blogs$: BehaviorSubject<Blog[]> = new BehaviorSubject<Blog[]>(null);
  campaigns$: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>(null);

  constructor(private http: HttpClient) {
  }

  products(filter: any = null):
    Observable<ProductsResponse> {
    let params = {};
    if (filter) {
      params = Utility.revert(filter);
    }
    return this.http.get<ProductsResponse>(environment.services.productService, {params})
    .pipe(
      map((obj) => {
        return obj;
      })
    )
    .pipe(share());
  }

  globalAsset(key: string):
    Observable<GlobalAsset> {
    return this.http.get<GlobalAssetResponse>(environment.services.globalAssetService + 'show/' + key)
    .pipe(
      map((obj) => {
        return obj.object;
      })
    )
    .pipe(share());
  }

  blogs(setBlogs: boolean = true,
        appendOrReset: 'append' | 'reset' = 'reset',
        filter: any = null):
    Observable<Blog[]> {

    if (appendOrReset === 'reset') {
      this.blogPagination.currentPage = 1;
    }

    let params;
    if (filter) {

      if (filter.hasOwnProperty('excludes') && filter.excludes?.length > 0) {
        filter.excludes = filter.excludes.join(',');
      } else {
        filter.excludes = undefined;
      }

      params = Utility.revert(Object.assign({}, {
        page: this.blogPagination.currentPage,
      }, filter));

    } else {

      params = Utility.revert({
        page: this.blogPagination.currentPage,
      });

    }

    return this.http.get<BlogsResponse>(environment.services.blogService, {params})
    .pipe(
      map((obj) => {
        this.blogPagination = obj.object;
        if (setBlogs) {
          if (appendOrReset === 'reset') {
            this.blogs$.next(obj.list);
          } else {
            const blogs = this.blogs$.getValue();
            obj.list.map(x => {
              if (!blogs.find(y => y.id === x.id)) {
                blogs.push(x);
              }
            });
            this.blogs$.next(blogs);
          }
        }
        return this.blogs$.getValue();
      })
    ).pipe(share());
  }

  like(request: any):
    Observable<any> {
    return this.http.post<any>(environment.services.likeService, request)
    .pipe(
      map((obj) => {
        return obj.object;
      })
    )
    .pipe(share());
  }

  share(contentUuid: string) {

  }

  contentComment(request: {contentId: number, page: number}):
    Observable<CommentsResponse> {
    return this.http.get<CommentsResponse>(environment.services.contentCommentService, {
      params: Utility.revert(request)
    })
    .pipe(
      map((obj) => {
        return obj;
      })
    )
    .pipe(share());
  }

  homePageComments():
    Observable<CommentsResponse> {
    return this.http.get<CommentsResponse>(environment.services.commentService + 'homePageComments')
    .pipe(
      map((obj) => {
        return obj;
      })
    )
    .pipe(share());
  }

  saveComment(request: {dataId: number, dataType: string, point: number, comment: string, id?: number}):
    Observable<Comment> {
    return this.http[request?.id ? 'put' : 'post']
      <CommentResponse>(environment.services.commentService, request)
    .pipe(
      map((obj) => {
        return obj.object;
      })
    )
    .pipe(share());
  }

  product(code: string):
    Observable<Product> {
    return this.http.get<ProductResponse>(environment.services.productService + code)
    .pipe(
      map((obj) => {
        return obj.object;
      })
    )
    .pipe(share());
  }

  authors():
    Observable<Author[]> {
    return this.http.get<AuthorsResponse>(environment.services.authorService)
    .pipe(
      map((obj) => {
        return obj.list;
      })
    )
    .pipe(share());
  }

  getAuthorBlogs(uuid: string):
    Observable<Blog[]> {
    return this.http.get<BlogsResponse>(environment.services.authorService + uuid)
    .pipe(
      map((obj) => {
        return obj.list;
      })
    )
    .pipe(share());
  }

  blog(slug: string):
    Observable<Blog> {
    return this.http.get<BlogResponse>(environment.services.blogService + slug)
    .pipe(
      map((obj) => {
        return obj.object;
      })
    )
    .pipe(share());
  }

  getContentCategories(request: { contentTypeKey: 'blog' | 'product' }):
    Observable<ContentCategory[]> {
    return this.http.get<ContentCategoriesResponse>(environment.services.contentCategoryService, {
      params: Utility.revert(request)
    })
    .pipe(
      map((obj) => {
        return obj.list;
      })
    )
    .pipe(share());
  }

  exampleMenu(menuId: number):
    Observable<Menu> {
    return this.http.get<MenuResponse>(environment.services.exampleMenuService, {
      params: Utility.revert({
        menuId
      })
    })
    .pipe(
      map((obj) => {
        return obj.object;
      })
    )
    .pipe(share());
  }
}
