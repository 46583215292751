import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPlayerComponent } from './video-player.component';
import {MatButtonModule} from '@angular/material/button';
import {DirectivesModule} from '../../directives/directives.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FlexModule} from '@angular/flex-layout';
// import {PlyrModule} from 'ngx-plyr';



@NgModule({
  declarations: [VideoPlayerComponent],
  exports: [
    VideoPlayerComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    DirectivesModule,
    MatTooltipModule,
    FlexModule,
    // PlyrModule
  ]
})
export class VideoPlayerModule { }
