import {HttpParams} from '@angular/common/http';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormControl, FormGroupDirective, NgForm} from '@angular/forms';

export class Utility {
  static convertModelToFormData(data = {}, form = null, namespace = '') {
    const files = {};
    let model = {};
    for (const propertyName in data) {
      if (data.hasOwnProperty(propertyName) && data[propertyName] instanceof File) {
        files[propertyName] = data[propertyName];
      } else {
        model[propertyName] = data[propertyName];
      }
    }

    model = JSON.parse(JSON.stringify(model));
    const formData = form || new FormData();

    for (const propertyName in model) {
      if (!model.hasOwnProperty(propertyName) || !model[propertyName]) {
        continue;
      }
      const formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
      if (model[propertyName] instanceof Date) {
        formData.append(formKey, model[propertyName].toISOString());
      } else if (model[propertyName] instanceof File) {
        formData.append(formKey, model[propertyName]);
      } else if (model[propertyName] instanceof Array) {
        model[propertyName].forEach((element, index) => {
          const tempFormKey = `${formKey}[${index}]`;
          if (typeof element === 'object') {
            this.convertModelToFormData(element, formData, tempFormKey);
          } else {
            formData.append(tempFormKey, element.toString());
          }
        });
      } else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File)) {
        this.convertModelToFormData(model[propertyName], formData, formKey);
      } else {
        formData.append(formKey, model[propertyName].toString());
      }
    }

    for (const propertyName in files) {
      if (files.hasOwnProperty(propertyName)) {
        formData.append(propertyName, files[propertyName]);
      }
    }

    return formData;
  }

  static revert(params: any): HttpParams {
    params = Object.assign({}, params);
    Object.keys(params).forEach(key => {
      if (typeof params[key] === 'object') {
        params[key] = JSON.stringify(params[key]);
      } else if (params[key] === undefined) {
        delete params[key];
      }
    });
    return params;
  }
}

export class ElmaErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
