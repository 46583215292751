import {Injectable, NgZone} from '@angular/core';
import Echo from 'laravel-echo';
import {AuthenticationService} from '../pages/authentication/authentication.service';
import {AppInitService} from './app-init.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../environments/environment';
import {LocalStorageService} from './local-storage.service';
import {NotificationService} from './notification.service';
import {ShoppingCartService} from '../modules/shopping-cart/shopping-cart.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  constructor(private authService: AuthenticationService,
              private translateService: TranslateService,
              private localStorage: LocalStorageService,
              private snackBar: NotificationService,
              private shoppingCartService: ShoppingCartService,
              private ngZone: NgZone,
              private appInitService: AppInitService) {

    this.startEcho();
  }

  startEcho() {
    this.ngZone.runOutsideAngular(() => {
      if (this.authService.loggedIn) {
        window.Echo = new Echo({
          broadcaster: 'socket.io',
          host: environment.socketServiceHost, // window.location.hostname
          authEndpoint: '/api/broadcasting/auth',
          namespace: 'Elma.Events',
          auth: {
            headers: {
              authorization: this.authService.auth.type + ' ' + this.authService.auth.token,
              lang: `${this.translateService.currentLang ? this.translateService.currentLang : 'tr'}`,
              'Content-Type': `application/vnd.example.v1+json`,
              Accept: `application/json`,
              AppUuid: environment.appUuid
            }
          }
        });
        this.listenPrivate();
      } else {
        window.Echo = new Echo({
          broadcaster: 'socket.io',
          host: environment.socketServiceHost
        });
      }

      window.Echo.channel('system')
      .listen('.system', (data) => {
        console.log(data);
        this.appInitService.process(data.data, false);
      });
    });
  }

  listenPrivate() {
    window.Echo.private(`profile.${this.authService.auth.profile.id}`)
    .listen('.profile', (data) => {

      this.authService.user = data.data;
      if (data.message) {
        this.snackBar[data.message.type](data.message.message);
      }
    });

    window.Echo.private(`shoppingCart.${environment.appUuid}.${this.localStorage.getItem('system.session')}`)
    .listen('.shoppingCart', (data) => {
      this.shoppingCartService.shoppingCart$.next(data.data);
    });
  }
}
