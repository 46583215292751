<div [@pageFadeAnimation]="getRouterOutletState(o)" class="d-flex w-100">
  <ngx-spinner bdColor="rgba(255,255,255,0.5)"
               [@fadeAnimation]
               size="large"
               color="rgba(185, 148, 98, .5)"
               type="ball-triangle-path"
               zIndex="999999999999"
               [fullScreen]="true">
  </ngx-spinner>
  <router-outlet #o="outlet"></router-outlet>
</div>

