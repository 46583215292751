import {
  HttpInterceptor as HttpInterceptorModel,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import {takeUntil} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpCancelService} from '../services/http-cancel.service';
import {ScopeService} from '../services/scope.service';

@Injectable()
export class HttpCancelInterceptor implements HttpInterceptorModel {

  constructor(private httpCancelService: HttpCancelService) {
  }

  intercept<T>(req: HttpRequest<T>, next: HttpHandler):
    Observable<HttpEvent<T>> {
    if (req.urlWithParams.indexOf('./') === -1 &&
      req.url.indexOf('shoppingCart') === -1) {
      return next.handle(req)
      .pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()));
    } else {
      return next.handle(req);
    }
  }
}
