import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AuthenticationService} from '../../../pages/authentication/authentication.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {parsePhoneNumberFromString, PhoneNumber} from 'libphonenumber-js';
import {MyService} from '../../../services/my.service';
import {ScopeService} from '../../../services/scope.service';

@Component({
  selector: 'app-call-me',
  templateUrl: './call-me.component.html',
  styleUrls: ['./call-me.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallMeComponent implements OnInit {

  form: FormGroup;
  addressPhoneFormatted: PhoneNumber;
  changePhoneForm: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CallMeComponent>,
    public authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private myService: MyService,
    public scopeService: ScopeService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      firstName: [this.authService.auth?.profile.firstName, Validators.required],
      lastName: [this.authService.auth?.profile.lastName, Validators.required],
      phone: [this.authService.auth?.profile.phone, Validators.required],
      saveMyPhone: [false, Validators.required],
      approve: [false, Validators.requiredTrue],
      request: ['Bilgi hattı', Validators.required],
    });

    if (this.authService.loggedIn) {
      this.form.patchValue({saveMyPhone: true});
    }

    if (this.authService.auth?.profile.phone) {
      this.form.patchValue({approve: true});
      this.addressPhoneFormatted = parsePhoneNumberFromString(this.authService.auth?.profile.phone);
    }
  }

  send() {
    this.myService
    .callMe(this.form.value)
    .subscribe(() => {
      this.dialogRef.close();
    });
  }

  changePhone() {
    this.changePhoneForm = true;
    this.form.patchValue({approve: false});
    this.cd.detectChanges();
  }
}
