import { Injectable } from '@angular/core';
// tslint:disable-next-line:ban-types
declare let fbq: Function;

@Injectable({
  providedIn: 'root'
})
export class FacebookPixelService {

  constructor() { }

  track(action: string, values: any = {}) {
    fbq('track', action, values);
  }
}
