import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SnackBarComponent} from './snack-bar/snack-bar.component';
import {MatButtonModule} from '@angular/material/button';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {MatRippleModule} from '@angular/material/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';


@NgModule({
    declarations: [SnackBarComponent],
    exports: [
        SnackBarComponent,
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        FlexModule,
        MatRippleModule,
        MatSnackBarModule,
        ExtendedModule,
    ]
})
export class SnackBarModule {
}
