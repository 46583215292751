<!--<video #target
       class="video-js vjs-theme-90fit vjs-big-play-centered mat-elevation-z8"
       controls playsinline preload="metadata">
  <p class="vjs-no-js">
    Bu videoyu görüntülemek için lütfen Javascript'i etkinleştirin ve HTML5 video desteği olan bir tarayıcı kullanın.
  </p>
</video>-->

<!--<div plyr
     #target
     [plyrSources]="sources"
     data-poster="/path/to/poster.jpg">
  &lt;!&ndash;<track kind="captions" label="English captions" src="/path/to/captions.vtt" srclang="en" default />&ndash;&gt;
</div>-->
<!--<div plyr
     #target
     (plyrQualityChange)="event($event)"
     [plyrTitle]="video.originalName"
     plyrType="video"
     [plyrPlaysInline]="true"
     [plyrCrossOrigin]="true"
     [plyrDriver]="hlsDriver"
     [plyrOptions]="options"
     [plyrSources]="sources"
     [plyrPoster]="poster"
     (plyrEnded)="videoEnd.emit(true)"
     (plyrInit)="player = $event">
</div>-->


<div #videoContainer id="videoContainer" class="youtube-theme">
  <video #videoPlayer crossorigin="anonymous" preload="metadata" id="youtube-theme" width="100%" height="100%"></video>
</div>
