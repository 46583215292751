<h1 mat-dialog-title class="px-3">{{title}}</h1>
<div mat-dialog-content class="px-3 pb-2">
  <div [innerHTML]="description"></div>
</div>
<div mat-dialog-actions class="px-3">
  <div fxFlex fxLayout="row" fxLayoutGap="10px"
       fxLayoutAlign="end end">
    <button mat-button
            aria-label="iptal"
            *ngIf="cancelButtonText"
            [mat-dialog-close]="false"
            [innerHTML]="cancelButtonText">
    </button>
    <button mat-flat-button
            [aria-label]="okButtonText"
            color="primary"
            cdkFocusInitial
            [mat-dialog-close]="true"
            [innerHTML]="okButtonText">
    </button>
  </div>
</div>
