import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShoppingCartIconComponent} from './shopping-cart-icon/shopping-cart-icon.component';
import {MatIconModule} from '@angular/material/icon';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {RouterModule} from '@angular/router';
import {AddCartComponent} from './add-cart/add-cart.component';
import {MatBadgeModule} from '@angular/material/badge';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatRippleModule} from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import {MdePopoverModule} from '@material-extended/mde';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {DirectivesModule} from '../../directives/directives.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {NgxSpinnerModule} from 'ngx-spinner';

@NgModule({
  declarations: [
    ShoppingCartIconComponent,
    AddCartComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    FlexModule,
    RouterModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    FormsModule,
    MatRippleModule,
    MatButtonModule,
    MdePopoverModule,
    MatCardModule,
    MatDividerModule,
    PerfectScrollbarModule,
    DirectivesModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    FlexLayoutModule,
    NgxSpinnerModule,
  ],
  exports: [
    MatBadgeModule,
    ShoppingCartIconComponent,
    AddCartComponent,
  ]
})
export class ShoppingCartModule {
}
