<mat-drawer-container [hasBackdrop]="layoutService.drawerBackdrop" autosize>
  <mat-drawer #drawer
              [mode]="layoutService.drawerMode"
              [autoFocus]="false"
              position="end"
              (closed)="layoutService.closeSidebar()"
              [opened]="layoutService.drawerIsOpen">
    <app-sidebar *ngIf="layoutService.drawerIsOpen && sidebar"></app-sidebar>
  </mat-drawer>
  <mat-drawer-content (scroll)="checkScroll($event)">
    <!--<ngx-capture [target]="screen" (resultImage)="saveImage($event)"></ngx-capture>-->
    <div class="fake-token-notification"
         *ngIf="authService.loggedIn && authService.auth.provider === 'fakeToken'">
      <strong>{{authService.auth.profile.fullName}}</strong> kullanıcı hesabıyla oturum açtınız. <a
      (click)="authService.logout()">Oturumu sonlandır</a>
    </div>
    <ngx-loading-bar color="#4bb39c" [includeSpinner]="false"></ngx-loading-bar>

    <header class="header">
      <div class="desktop-header"
           *ngIf="device === 'desktop'">
        <div class="container">
          <div fxFlex fxLayout="row" fxLayoutGap="10px"
               fxLayoutAlign="space-between center">

            <div fxFlex="0 0 300px"
                 fxLayout="row" fxLayoutGap="10px">
              <div fxFlex fxLayout="column" fxLayoutGap="5px" class="header-box">
                <strong>WhatsApp</strong>
                <a href="tel:905459034890">+90 545 90 348 90</a>
              </div>
              <div fxFlex fxLayout="column" fxLayoutGap="5px" class="header-box">
                <strong>E-Posta</strong>
                <a href="mailto:bilgi@my90fit.com">bilgi@my90fit.com</a>
              </div>
            </div>

            <div fxFlex fxLayoutAlign="center center" fxLayout="column">
              <a routerLink="/"
                 class="logo"
                 [ngClass]="{'show-blog': showBlog, 'scaled-logo': !isSticky}">
                <img alt="90Fit" class="img-fluid" width="300" height="135" src="/assets/img/logo.svg">
                <!--<object data="/assets/img/logo.svg" type="image/svg+xml"></object>-->
                <span *ngIf="showBlog">blog</span>
              </a>
              <div class="text-center logo-slogan animated bounceIn accent-color">Diyetisyenlerin tasarladığı menüler
                her sabah adresinde!
              </div>
            </div>

            <div fxFlex="0 0 300px" fxLayoutAlign="end center">
              <div>
                <a mat-stroked-button
                   [matMenuTriggerFor]="accountMenu2"
                   color="primary"
                   class="d-flex flex-row align-items-center desktop-welcome-message">
                  <span class="pe-2">{{authService.loggedIn ? authService.auth?.profile?.fullName : 'Oturum aç'}}</span>
                  <mat-icon color="accent">account_circle</mat-icon>
                </a>

                <mat-menu #accountMenu2="matMenu" yPosition="below" xPosition="before">
                  <ng-container *ngIf="authService.loggedIn; else guestMenu">
                    <ng-container *ngFor="let menu of accountService.accountMenu">
                      <button mat-menu-item
                              aria-label="menu"
                              *ngIf="menu.hasOwnProperty('url')"
                              [disabled]="menu?.disabled"
                              routerLinkActive="active"
                              [routerLink]="menu.url">
                        <mat-icon class="me-2">{{menu.icon}}</mat-icon>
                        {{menu.text | translate}}
                      </button>

                      <button mat-menu-item
                              aria-label="menu"
                              *ngIf="menu.hasOwnProperty('click')"
                              [disabled]="menu?.disabled"
                              appConfirm
                              (status)="confirm($event, menu)">
                        <mat-icon class="me-2">{{menu.icon}}</mat-icon>
                        {{menu.text | translate}}
                      </button>
                    </ng-container>
                  </ng-container>

                  <ng-template #guestMenu>
                    <button mat-menu-item
                            aria-label="giriş yap"
                            routerLink="/auth/login">
                      <mat-icon class="me-2">vpn_key</mat-icon>
                      {{'account.login' | translate}}
                    </button>
                    <button mat-menu-item
                            aria-label="kayıt ol"
                            routerLink="/auth/register">
                      <mat-icon class="me-2">person_add</mat-icon>
                      {{'sidebar.register' | translate}}
                    </button>
                  </ng-template>
                </mat-menu>
              </div>
              <div *ngIf="!isSticky">
                <app-shopping-cart-icon fxFlex></app-shopping-cart-icon>
              </div>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="container">
          <div class="navigation-menu">
            <div class="container"
                 fxLayout="row"
                 fxLayoutAlign="center center"
                 fxLayoutGap="20px">
              <ng-container *ngFor="let menuItem of menu">
                <ng-container *ngIf="!menuItem.disabled">
                  <a [routerLink]="!menuItem.disabled ? menuItem.link : null"
                     *ngIf="menuItem.link === '/'; else links2"
                     [fxHide]="menuItem.disabled"
                     [ngClass]="{disabled: menuItem.disabled}"
                     [class]="menuItem.key"
                     [routerLinkActiveOptions]="{exact: true}"
                     routerLinkActive="active">
                    {{menuItem.name | translate}}
                  </a>
                  <ng-template #links2>
                    <a [routerLink]="!menuItem.disabled ? menuItem.link : null"
                       [ngClass]="{disabled: menuItem.disabled}"
                       [class]="menuItem.key"
                       routerLinkActive="active">
                      {{menuItem.name | translate}}
                    </a>
                  </ng-template>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile-header"
           *ngIf="device === 'mobile'">
        <div class="container d-flex flex-column">
          <div fxFlex
               fxLayout="row"
               fxLayoutGap="10px"
               fxLayoutAlign="space-between center">

            <div fxFlex="0 0 80px">
              <button mat-icon-button
                      aria-label="sidebar"
                      color="accent"
                      (click)="layoutService.toggleSidebar()">
                <mat-icon>menu</mat-icon>
              </button>
            </div>

            <div fxFlex fxLayoutAlign="center center" fxLayout="column">
              <a routerLink="/"
                 class="logo"
                 [ngClass]="{'show-blog': showBlog, 'scaled-logo': !isSticky}">
                <img alt="90Fit" class="img-fluid" width="300" height="135" src="/assets/img/logo.svg">
                <!--<object data="/assets/img/logo.svg" type="image/svg+xml"></object>-->
                <span *ngIf="showBlog">blog</span>
              </a>
            </div>

            <div fxFlex="0 0 80px"
                 fxLayoutAlign="end center">
              <div>
                <button mat-icon-button
                        aria-label="hesabım"
                        [matMenuTriggerFor]="accountMenu">
                  <mat-icon color="accent">account_circle</mat-icon>
                </button>

                <mat-menu #accountMenu="matMenu" yPosition="below" xPosition="before">
                  <ng-container *ngIf="authService.loggedIn; else guestMenu">
                    <ng-container *ngFor="let menu of accountService.accountMenu">
                      <button mat-menu-item
                              aria-label="hesap menusu"
                              *ngIf="menu.hasOwnProperty('url')"
                              [disabled]="menu?.disabled"
                              routerLinkActive="active"
                              [routerLink]="menu.url">
                        <mat-icon class="me-2">{{menu.icon}}</mat-icon>
                        {{menu.text | translate}}
                      </button>

                      <button mat-menu-item
                              aria-label="hesap menusu"
                              *ngIf="menu.hasOwnProperty('click')"
                              [disabled]="menu?.disabled"
                              appConfirm
                              (status)="confirm($event, menu)">
                        <mat-icon class="me-2">{{menu.icon}}</mat-icon>
                        {{menu.text | translate}}
                      </button>
                    </ng-container>
                  </ng-container>

                  <ng-template #guestMenu>
                    <button mat-menu-item
                            aria-label="giriş yap"
                            routerLink="/auth/login">
                      <mat-icon class="me-2">vpn_key</mat-icon>
                      {{'account.login' | translate}}
                    </button>
                    <button mat-menu-item
                            aria-label="kayıt ol"
                            routerLink="/auth/register">
                      <mat-icon class="me-2">person_add</mat-icon>
                      {{'sidebar.register' | translate}}
                    </button>
                  </ng-template>
                </mat-menu>
              </div>
              <div *ngIf="!isSticky">
                <app-shopping-cart-icon fxFlex></app-shopping-cart-icon>
              </div>
            </div>
          </div>
          <div class="text-center logo-slogan animated bounceIn accent-color">Diyetisyenlerin tasarladığı menüler her
            sabah adresinde!
          </div>
        </div>
      </div>
    </header>

    <div class="fixed-header"
         [ngClass]="{'mat-elevation-z5' : true, 'sticky' : isSticky}">

      <div class="desktop-header"
           *ngIf="device === 'desktop'">
        <div class="container">
          <div fxFlex fxLayout="row" fxLayoutGap="10px"
               fxLayoutAlign="space-between center">
            <div fxFlex="0 0 100px">
              <a routerLink="/"
                 class="logo"
                 [ngClass]="{'show-blog': showBlog, 'scaled-logo': isSticky}">
                <img alt="90Fit" class="img-fluid" width="100%" src="/assets/img/logo.svg">
                <!--<object data="/assets/img/logo.svg" type="image/svg+xml"></object>-->
                <span *ngIf="showBlog">blog</span>
              </a>
            </div>

            <div class="navigation-menu">
              <div class="container"
                   fxLayout="row"
                   fxLayoutAlign="center center"
                   fxLayoutGap="20px">
                <ng-container *ngFor="let menuItem of menu">
                  <ng-container *ngIf="!menuItem.disabled">
                    <a [routerLink]="!menuItem.disabled ? menuItem.link : null"
                       *ngIf="menuItem.link === '/'; else links2"
                       [ngClass]="{disabled: menuItem.disabled}"
                       [class]="menuItem.key"
                       [routerLinkActiveOptions]="{exact: true}"
                       routerLinkActive="active">
                      {{menuItem.name | translate}}
                    </a>
                    <ng-template #links2>
                      <a [routerLink]="!menuItem.disabled ? menuItem.link : null"
                         [ngClass]="{disabled: menuItem.disabled}"
                         [class]="menuItem.key"
                         routerLinkActive="active">
                        {{menuItem.name | translate}}
                      </a>
                    </ng-template>
                  </ng-container>
                </ng-container>
              </div>
            </div>

            <div fxFlex="0 0 100px"
                 fxLayoutAlign="end center">
              <div>
                <a mat-stroked-button
                   [matMenuTriggerFor]="accountMenu"
                   color="primary"
                   class="d-flex flex-row align-items-center desktop-welcome-message">
                  <span class="pe-2">{{authService.loggedIn ? authService.auth?.profile?.fullName : 'Oturum aç'}}</span>
                  <mat-icon color="accent">account_circle</mat-icon>
                </a>

                <mat-menu #accountMenu="matMenu" yPosition="below" xPosition="before">
                  <ng-container *ngIf="authService.loggedIn; else guestMenu">
                    <ng-container *ngFor="let menu of accountService.accountMenu">
                      <button mat-menu-item
                              aria-label="hesap menüsü"
                              *ngIf="menu.hasOwnProperty('url')"
                              [disabled]="menu?.disabled"
                              routerLinkActive="active"
                              [routerLink]="menu.url">
                        <mat-icon class="me-2">{{menu.icon}}</mat-icon>
                        {{menu.text | translate}}
                      </button>

                      <button mat-menu-item
                              aria-label="hesap menüsü"
                              *ngIf="menu.hasOwnProperty('click')"
                              [disabled]="menu?.disabled"
                              appConfirm
                              (status)="confirm($event, menu)">
                        <mat-icon class="me-2">{{menu.icon}}</mat-icon>
                        {{menu.text | translate}}
                      </button>
                    </ng-container>
                  </ng-container>

                  <ng-template #guestMenu>
                    <button mat-menu-item
                            aria-label="giriş yap"
                            routerLink="/auth/login">
                      <mat-icon class="me-2">vpn_key</mat-icon>
                      {{'account.login' | translate}}
                    </button>
                    <button mat-menu-item
                            aria-label="kayıt ol"
                            routerLink="/auth/register">
                      <mat-icon class="me-2">person_add</mat-icon>
                      {{'sidebar.register' | translate}}
                    </button>
                  </ng-template>
                </mat-menu>
              </div>
              <div *ngIf="isSticky">
                <app-shopping-cart-icon fxFlex></app-shopping-cart-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile-header"
           *ngIf="device === 'mobile'">
        <div class="container">
          <div fxFlex
               fxLayout="row"
               fxLayoutGap="10px"
               fxLayoutAlign="space-between center">

            <div fxFlex="0 0 80px">
              <button mat-icon-button
                      aria-label="menu"
                      color="accent"
                      (click)="layoutService.toggleSidebar()">
                <mat-icon>menu</mat-icon>
              </button>
            </div>

            <div fxFlex fxLayoutAlign="center center">
              <a routerLink="/"
                 class="logo"
                 [ngClass]="{'show-blog': showBlog, 'scaled-logo': isSticky}">
                <img alt="90Fit" class="img-fluid" width="100%" src="/assets/img/logo.svg">
                <!--<object data="/assets/img/logo.svg" type="image/svg+xml"></object>-->
                <span *ngIf="showBlog">blog</span>
              </a>
            </div>

            <div fxFlex="0 0 80px"
                 fxLayoutAlign="end center">
              <div>
                <button mat-icon-button
                        aria-label="menu"
                        [matMenuTriggerFor]="accountMenu">
                  <mat-icon color="accent">account_circle</mat-icon>
                </button>

                <mat-menu #accountMenu="matMenu" yPosition="below" xPosition="before">
                  <ng-container *ngIf="authService.loggedIn; else guestMenu">
                    <ng-container *ngFor="let menu of accountService.accountMenu">
                      <button mat-menu-item
                              aria-label="menu item"
                              *ngIf="menu.hasOwnProperty('url')"
                              [disabled]="menu?.disabled"
                              routerLinkActive="active"
                              [routerLink]="menu.url">
                        <mat-icon class="me-2">{{menu.icon}}</mat-icon>
                        {{menu.text | translate}}
                      </button>

                      <button mat-menu-item
                              *ngIf="menu.hasOwnProperty('click')"
                              [disabled]="menu?.disabled"
                              aria-label="menu item"
                              appConfirm
                              (status)="confirm($event, menu)">
                        <mat-icon class="me-2">{{menu.icon}}</mat-icon>
                        {{menu.text | translate}}
                      </button>
                    </ng-container>
                  </ng-container>

                  <ng-template #guestMenu>
                    <button mat-menu-item
                            aria-label="giriş yap"
                            routerLink="/auth/login">
                      <mat-icon class="me-2">vpn_key</mat-icon>
                      {{'account.login' | translate}}
                    </button>
                    <button mat-menu-item
                            aria-label="kayıt ol"
                            routerLink="/auth/register">
                      <mat-icon class="me-2">person_add</mat-icon>
                      {{'sidebar.register' | translate}}
                    </button>
                  </ng-template>
                </mat-menu>
              </div>
              <div *ngIf="isSticky">
                <app-shopping-cart-icon fxFlex></app-shopping-cart-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-container">
      <div class="page-content">

        <!--        {{router.url}}-->

        <div class="breadcrumb-container" *ngIf="showBreadcrumbs && header">
          <div class="container">
            <xng-breadcrumb [autoGenerate]="true"
                            [separator]="iconTemplate">
              <ng-container
                *xngBreadcrumbItem="let breadcrumb; let info = info; let first = first; let last = last">
                <ng-container *ngIf="first">
                  <mat-icon
                    *ngIf="router.url.indexOf('/blog/') > -1 || router.url.indexOf('/products/') > -1; else home">undo
                  </mat-icon>
                  <ng-template #home>
                    <mat-icon>home</mat-icon>
                  </ng-template>
                </ng-container>
                <ng-container *ngIf="!first">{{breadcrumb}}</ng-container>
              </ng-container>
            </xng-breadcrumb>
          </div>
        </div>

        <ng-template #iconTemplate>
          <mat-icon color="primary">chevron_right</mat-icon>
        </ng-template>

        <div class="header-marquee" *ngIf="campaigns && campaigns.length > 0">
          <div class="container">
            <div class="d-flex">
              <div class="d-flex flex-grow-0 pe-2 align-items-center justify-content-center">
                <mat-icon class="bell-ring" color="primary">notifications</mat-icon>
              </div>
              <div class="d-flex flex-grow-1 position-relative">
                <div class="v-slider-frame">
                  <ul class="v-slides">
                    <li class="v-slide primary-color cursor-pointer"
                        [routerLink]="'/products/item/' + campaign.slug"
                        [queryParams]="campaign.productItem?.meta"
                        *ngFor="let campaign of campaigns; let last = last">
                      <div class="white-space-nowrap"
                           ngClass.gt-xs="fs-14"
                           ngClass.lt-sm="fs-12"
                           [innerHTML]="'<strong>' + (campaign.name || campaign.productItem.name) + '</strong> ' +
                        ('campaigns.campaign_text' | translate:{calorie: campaign.productItem.meta.calorie, day: campaign.productItem.meta.day, salePrice: scopeService.priceFormatter(campaign.productItem.salePriceFormatted, false), price: scopeService.priceFormatter(campaign.productItem.priceFormatted, false)})"></div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--<main [@pageFadeAnimation]="getRouterOutletState(o)">
          <ngx-spinner bdColor="rgba(255,255,255,0.5)"
                       [@fadeAnimation]
                       size="large"
                       color="rgba(185, 148, 98, .5)"
                       type="ball-triangle-path"
                       zIndex="999999999999"
                       [fullScreen]="true">
          </ngx-spinner>
          <router-outlet #o="outlet"></router-outlet>
        </main>-->

        <main>
          <ng-content></ng-content>
        </main>
      </div>
    </div>

    <footer *ngIf="footer">
      <div class="container">
        <div fxFlex fxLayout="row"
             fxLayoutAlign="start"
             fxLayout.lt-md="row wrap"
             fxLayoutGap="20px grid" class="footer-container">
          <div fxFlex="25%" fxFlex.lt-md="40%" class="d-flex align-items-center">
            <img alt="90Fit" src="/assets/img/90fit-logo.png" width="300" height="285" alt="90Fit"
                 class="img-fluid h-auto footer-logo">
          </div>

          <!--<form [formGroup]="registerForm" (ngSubmit)="register()">
            <div class="d-flex flex-column">
              <div class="footer-input no-padding-input">
                <mat-form-field appearance="outline" fxFlex fxLayoutGap="10px">
                  <mat-label>{{'footer.join_newsletter' | translate}}</mat-label>
                  <div matPrefix>
                    <mat-icon class="me-2">email</mat-icon>
                  </div>
                  <input matInput
                         formControlName="email"
                         autocomplete="off"
                         [placeholder]="'footer.enter_email' | translate">
                </mat-form-field>
              </div>

              <div class="footer-slide-toggle my-3">
                <mat-slide-toggle [checked]="createAccount" (change)="formTypeChanged($event)" color="primary">
                  <ng-container *ngIf="createAccount">{{'footer.create_account' | translate}}</ng-container>
                  <ng-container *ngIf="!createAccount">{{'footer.only_newsletter' | translate}}</ng-container>
                </mat-slide-toggle>
              </div>

              <button mat-raised-button color="primary"
                      [disabled]="!registerForm.invalid || registerForm.pending">
                <ng-container *ngIf="!createAccount">{{'footer.add_newsletter' | translate}}</ng-container>
                <ng-container *ngIf="createAccount">{{'footer.add_newsletter_and_register' | translate}}</ng-container>
              </button>
            </div>
          </form>-->

          <div fxFlex="25%" fxFlex.lt-md="60%" class="footer-menu">
            <strong>Bilgi Sayfaları</strong>
            <a routerLink="/pages/hakkimizda">{{'footer.about' | translate}}</a>
            <a routerLink="/pages/iletisim">{{'footer.contact' | translate}}</a>
            <a routerLink="/pages/sikca-sorulan-sorular">{{'footer.faq' | translate}}</a>
            <a routerLink="/pages/iade-politikasi">{{'footer.policy' | translate}}</a>
            <a routerLink="/pages/kullanici-sozlesmesi">{{'footer.user_contract' | translate}}</a>
            <a routerLink="/pages/kvkk">{{'footer.kvkk' | translate}}</a>
          </div>

          <div fxFlex="50%"
               fxFlex.lt-md="100%"
               class="footer-menu d-flex flex-column">
            <strong>İletişim</strong>
            <div class="d-flex flex-row mb-2" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-icon>location_on</mat-icon>
              <span>Balabandere Caddesi, Esenlik Sokak, 3/A<br>İstinye Mahallesi, Sarıyer, 34457, İstanbul</span>
            </div>
            <div class="d-flex flex-row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-icon>phone</mat-icon>
              <a href="tel:+905459034890">+90 545 90 348 90</a>
            </div>
            <div class="mt-5 d-inline-flex flex-column justify-content-start">
              <strong class="mb-3">Ödeme Seçenekleri</strong>

              <div class="mb-2 d-inline-flex">
                <img src="/assets/img/logo-bands.svg"
                     width="594" height="32"
                     matTooltip="Kredi Kartı ile öde"
                     class="img-fluid"
                     style="height: 20px; width: auto"
                     alt="Kredi Kartı ile Ödeme">
              </div>

              <div class="d-inline-flex flex-row align-items-center justify-content-start mb-3"
                   fxLayoutGap="10px">
                <div>
                  <img src="/assets/img/pluxee.png"
                       matTooltip="Pluxee ile öde"
                       class="img-fluid mr-3"
                       style="max-height: 25px; width: 100%" alt="Pluxee ile Ödeme">
                </div>
                <div>
                  <img src="/assets/icons/multinet.png"
                       width="1779" height="363"
                       matTooltip="MultiNet ile öde"
                       class="img-fluid"
                       style="max-height: 25px; width: 100%" alt="MultiNet ile Ödeme">
                </div>
              </div>
              <div class="d-flex align-items-center" fxLayoutGap="10px">
                <!--<img src="/assets/icons/bank-transfer.png" matTooltip="Banka havalesi ile öde" class="img-fluid" style="max-height: 35px" alt="MultiNet ile Ödeme">-->
                <mat-icon>account_balance</mat-icon>
                <strong class="mb-0 fs-14" style="opacity: .6">Banka Havalesi</strong>
              </div>
              <!--<object data="/assets/img/logo-band_iyzico ile Ode.svg" type="image/svg+xml"></object>-->
            </div>
          </div>
        </div>
      </div>
      <div class="sub-footer">
        <div class="container py-3">
          <div fxFlex
               fxLayout="row"
               fxLayout.lt-md="column"
               fxLayoutAlign.lt-md="start"
               fxLayoutGap="10px"
               ngClass.lt-md="fs-12"
               fxLayoutAlign="space-between center">
            <div>© 2019 90Fit | Kendine iyi bakmayı seçenlerin en sevdiği platform</div>
            <div>MDB Danışmanlık ve Platform Hizmetleri A.Ş.</div>
          </div>
        </div>
      </div>
    </footer>

    <div class="quick-register" *ngIf="!authService.loggedIn && welcomeCoupon">
      <button mat-fab class="fab-toggler"
              aria-label="hızlı menü"
              [mdePopoverTriggerFor]="appPopover"
              mdePopoverTriggerOn="click"
              [mdePopoverEnterDelay]="700"
              [mdePopoverCloseOnClick]="false"
              [mdePopoverOverlapTrigger]="true"
              mdePopoverArrowColor="var(--primary-color)"
              #popoverTrigger="mdePopoverTrigger"
              color="primary"
              [disabled]="!connection"
              (click)="registerAnimation();">
        <i class="material-icons animate-icon"
           [style.fontSize.px]="30"
           [@fabToggler]="{value: fabTogglerState}">
          {{fabTogglerState === 'active' ? 'close' : 'star'}}
        </i>
      </button>

      <mde-popover #appPopover="mdePopover"
                   [mdeFocusTrapEnabled]="false"
                   [mdeFocusTrapAutoCaptureEnabled]="false"
                   [mdePopoverArrowWidth]="10"
                   (close)="registerAnimation()"
                   [mdePopoverOverlapTrigger]="true">
        <mat-card style="max-width: 280px" class="d-flex flex-column">
          <div class="border-bottom-1 pb-1 mb-2">
            <div class="d-flex flex-row" fxLayoutAlign="start center">
              <mat-icon [inline]="true" color="primary" class="me-2 fs-23">star</mat-icon>
              <h3 class="fw-500 m-0 p-0">Fırsat !</h3>
            </div>
          </div>

          <div class="mb-2">
            Tek tıkla kayıt ol ilk siparişinde
            <strong
              [innerHTML]="welcomeCoupon?.priceFormatted ? scopeService.priceFormatter(welcomeCoupon?.priceFormatted, true) : '%' + welcomeCoupon?.percent"></strong>
            indirim kazan !
          </div>

          <a mat-stroked-button
             fxFlex="1 0 100%"
             class="py-1 mx-0 mb-2"
             href="{{environment}}/auth/google?emailNotifications={{approveEmail ? 'true' : 'false'}}&session={{lss.getItem('system.session')}}">
            <i class="elma-icons google me-2"></i> Google ile bağlan
          </a>

          <mat-checkbox class="mb-2 fs-12" [(ngModel)]="approveEmail">Fırsatlardan haberdar olmak istiyorum
          </mat-checkbox>

          <div class="mb-2">
            <mat-divider></mat-divider>
          </div>

          <small>Kişisel verileriniz, <a routerLink="/pages/kvkk">KVKK aydınlatma metni</a> kapsamında işlenmektedir.
            “Google ile bağlan” butonuna basarak <a routerLink="/pages/kullanici-sozlesmesi">Kullanıcı Sözleşmesi</a>’ni,
            okuduğunuzu ve kabul ettiğinizi onaylıyorsunuz.</small>
        </mat-card>
      </mde-popover>
    </div>
    <app-quick-menu [connection]="isConnected"></app-quick-menu>
  </mat-drawer-content>
</mat-drawer-container>

<div class="cookies-policy" *ngIf="!cookiesPolicy">
  <div class="container">
    <div class="d-flex flex-row align-items-center justify-content-between">
      <div class="d-flex flex-row align-items-center">
        <mat-icon class="me-1">info</mat-icon>
        <div>Hizmetlerimizden en iyi şekilde faydalanabilmeniz için çerezler kullanıyoruz. 90fit.com.tr'yi kullanarak
          çerezlere izin vermiş olursunuz.
        </div>
      </div>
      <div class="d-flex flex-row align-items-center">
        <div (click)="cookiesPolicy = true"
             matRipple
             class="cursor-pointer d-flex flex-row align-items-center">
          <mat-icon>close</mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<div *ngIf="!isConnected" class="not-connected">
  <div class="text-center">
    <mat-icon>wifi_off</mat-icon>
    <h3>{{'system.connection_error' | translate}}</h3>
  </div>
</div>-->
