import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {LoggedInGuard} from './guards/logged-in.guard';
import {VerifiedGuard} from './guards/verified.guard';
import {ExternalLoginGuard} from './interceptors/external-login.guard';


const routes: Routes = [
  {
    path: '',
    canActivate: [ExternalLoginGuard],
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./pages/authentication/authentication.module').then(mod => mod.AuthenticationModule),
        data: {
          breadcrumb: 'Hesabım'
        }
      },
      {
        path: 'account',
        loadChildren: () => import('./pages/account/account.module').then(mod => mod.AccountModule),
        canActivate: [LoggedInGuard],
        data: {
          breadcrumb: 'Hesabım'
        }
      },
      {
        path: 'products',
        loadChildren: () => import('./pages/products/products.module').then(mod => mod.ProductsModule),
        canActivate: [VerifiedGuard],
        data: {
          breadcrumb: 'Programlar'
        }
      },
      {
        path: 'payment',
        loadChildren: () => import('./pages/payment/payment.module').then(mod => mod.PaymentModule),
        canActivate: [LoggedInGuard, VerifiedGuard],
        data: {
          breadcrumb: 'Ödeme'
        }
      },
      {
        path: 'campaigns',
        loadChildren: () => import('./pages/campaigns/campaigns.module').then(mod => mod.CampaignsModule),
        canActivate: [VerifiedGuard],
        data: {
          breadcrumb: 'Kampanyalar'
        }
      },
      {
        path: 'blog',
        loadChildren: () => import('./pages/blog/blog.module').then(mod => mod.BlogModule),
        canActivate: [VerifiedGuard],
        data: {
          breadcrumb: 'Blog'
        }
      },
      {
        path: 'online',
        loadChildren: () => import('./pages/online/online.module').then(mod => mod.OnlineModule),
        canActivate: [VerifiedGuard],
        data: {
          breadcrumb: 'Online Hizmetler'
        }
      },
      {
        path: 'pages',
        loadChildren: () => import('./pages/static/static.module').then(mod => mod.StaticModule),
        canActivate: [VerifiedGuard],
        data: {
          breadcrumb: {
            skip: true
          }
        }
      },
      {
        path: '',
        loadChildren: () => import('./pages/home/home.module').then(mod => mod.HomeModule),
        canActivate: [VerifiedGuard],
        data: {
          breadcrumb: {
            info: 'home',
            label: 'Anasayfa'
          }
        }
      }
    ]
  },
  /*{
    path: '',
    redirectTo: 'operation/dashboard',
    pathMatch: 'full'
  },*/
  {
    path: '404',
    component: NotFoundComponent,
    data: {
      breadcrumb: {
        skip: true
      }
    }
  },
  {
    path: '**',
    redirectTo: '/404'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

