import {Injectable} from '@angular/core';
import {AuthenticationService} from '../authentication/authentication.service';
import {AccountMenuItem} from '../../interfaces/DataModel';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private authenticationService: AuthenticationService) {
  }

  accountMenu: AccountMenuItem[] = [{
    icon: 'mood',
    url: '/account/update',
    text: 'account.update_profile',
  }, {
    icon: 'lock',
    url: '/account/password-change',
    text: 'account.password_change',
  }, {
    icon: 'shopping_cart',
    url: '/account/orders',
    text: 'account.orders',
  }, {
    icon: 'room',
    url: '/account/addresses',
    text: 'account.addresses',
  }, {
/*    icon: 'insert_comment',
    url: '/account/comments',
    text: 'account.comments',
    disabled: true,
  }, {
    icon: 'notifications',
    url: '/account/notifications',
    text: 'account.notifications',
  }, {*/
    icon: 'credit_card',
    url: '/account/credit-cards',
    text: 'account.credit-cards.credit-cards',
  }, {
    icon: 'exit_to_app',
    click: () => this.authenticationService.logout(),
    text: 'account.logout',
  }];
}
