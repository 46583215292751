import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {LayoutService} from '../layout.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {MyService} from '../../services/my.service';
import {BreadcrumbService} from 'xng-breadcrumb';
import {ScopeService} from '../../services/scope.service';
import {fadeAnimation, pageFadeAnimation} from '../../animations/system.animation';
import {NgConnection} from 'ng-connection';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {HttpCancelService} from '../../services/http-cancel.service';
import {ShoppingCartService} from '../../modules/shopping-cart/shopping-cart.service';
import {Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {LocalStorageService} from '../../services/local-storage.service';
import moment from 'moment';
import {AppInitService} from '../../services/app-init.service';
import {Coupon, Environment, Module, Product} from '../../interfaces/DataModel';
import {AuthenticationService} from '../../pages/authentication/authentication.service';
import {NotificationService} from '../../services/notification.service';
import {AccountService} from '../../pages/account/account.service';
import {SpeedDialFabAnimations} from '../../animations/speed-dial-fab.animations';
import {LocalStorage} from 'ngx-webstorage';
import {MdePopoverTrigger} from '@material-extended/mde';
import {ContentService} from '../../services/content.service';
import {environment} from 'src/environments/environment';
import {TimelineMax, Elastic} from 'gsap/dist/gsap';

declare var $;

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeAnimation,
    SpeedDialFabAnimations,
  ],
})
export class DefaultLayoutComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('drawer', {static: true}) drawer: MatDrawer;
  @ViewChild(MdePopoverTrigger, {static: false}) trigger: MdePopoverTrigger;
  @Input() sidebar: boolean = true;
  @Input() toolbar: boolean = true;
  @Input() title: string = null;
  @Input() description: string = null;
  @Input() viewType: 'empty' | 'default' = 'empty';
  @Input() showBlog: boolean = false;
  @Input() header: boolean = true;
  @Input() footer: boolean = true;
  public registerForm: FormGroup;
  public createAccount: boolean = true;
  public showBreadcrumbs: boolean = true;
  public isConnected: boolean = true;
  private destroySubject$: Subject<any> = new Subject<any>();
  menu: Module[] = [];
  snackBarTimeout: any;
  scrollTimeout: any;
  device: 'desktop' | 'mobile';
  fabTogglerState = 'inactive';
  @LocalStorage() cookiesPolicy: boolean;
  approveEmail: boolean = true;

  isSticky: boolean = false;
  welcomeCoupon: Coupon;
  products: Product[] = [];
  campaigns: Product[] = [];
  marquee: any;
  environment: Environment = environment;

  constructor(public layoutService: LayoutService,
              private myService: MyService,
              public connection: NgConnection,
              public breadcrumbService: BreadcrumbService,
              public scopeService: ScopeService,
              public router: Router,
              public route: ActivatedRoute,
              private httpCancelService: HttpCancelService,
              private snackBar: NotificationService,
              private shoppingCartService: ShoppingCartService,
              public lss: LocalStorageService,
              private cd: ChangeDetectorRef,
              public authService: AuthenticationService,
              private appInitService: AppInitService,
              public accountService: AccountService,
              private contentService: ContentService,
              private formBuilder: FormBuilder) {
  }

  checkAdminRole(checkRole: string) {
    this.authService.auth.roles.some(role => role === checkRole);
  }

  ngOnInit() {
    this.contentService.campaigns$
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((campaigns) => {
        if (campaigns) {
          this.products = campaigns;
          this.setProductItems();
          this.cd.detectChanges();
        }
      });

    this.lss.storageSubject
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        this.welcomeCoupon = this.lss.getItem('system.welcomeCoupon');
        if (this.welcomeCoupon) {
          this.trigger?.closePopover();
          this.fabTogglerState = 'inactive';
          this.cd.detectChanges();
        }
      });

    this.scopeService.menu
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(menu => {
        this.menu = menu;
        this.cd.detectChanges();
      });

    this.connection.connectivity(true)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isConnected => {
        this.isConnected = isConnected;
        this.cd.detectChanges();
      });

    this.breadcrumbService.breadcrumbs$
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(breadcrumbs => {
        return this.showBreadcrumbs = breadcrumbs.length > 1;
      });

    this.layoutService.drawer = this.drawer;
    this.registerForm = this.formBuilder.group({
      email: [null, [Validators.email, Validators.required]],
    });

    this.router.events
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(event => {
        if (event instanceof NavigationStart) {
          this.httpCancelService.cancelPendingRequests();
          this.shoppingCartService.shoppingCartPopover?.closePopover();

          if (!this.authService.loggedIn) {
            this.trigger?.closePopover();
            this.fabTogglerState = 'inactive';
          }
        }

        if (event instanceof NavigationEnd) {
          this.snackBarTimeout = setTimeout(() => {
            if (this.router.url.indexOf('/auth/') !== 0) {
              this.snackBar.dismiss();
            }
          }, 1000);

          this.layoutService.closeSidebar();

          if (!this.authService.loggedIn) {
            $('.quick-register button .material-icons').removeClass('animate-icon');
          }

          this.scrollTimeout = setTimeout(() => {
            this.layoutService.scrollTo('.mat-drawer-content');

            if (!this.authService.loggedIn) {
              $('.quick-register button .material-icons').addClass('animate-icon');
            }
          }, 300);
        }
      });

    this.onResize();
  }

  setProductItems() {
    const products = JSON.parse(JSON.stringify(this.products));

    const campaigns = [];

    products.map(product => {
      product.productItems.map(productItem => {
        const p = JSON.parse(JSON.stringify(product));
        p.productItem = productItem;
        campaigns.push(p);
      });
    });

    this.campaigns = campaigns;

    this.cd.detectChanges();

    setTimeout(() => {
      if (this.marquee) {
        this.marquee.kill({x: true, y: true});
      }

      this.marquee = new TimelineMax({
        paused: true,
        repeat: -1,
        repeatDelay: 3,
      });

      this.campaigns.map((x, i) => {
        this.marquee.to($('.v-slides'), 1.5, {
          y: (i * -1) * 45,
          ease: Elastic.easeOut.config(1, .4),
          delay: i === 0 ? 0 : 3,
        });
      });

      this.marquee.play();
    }, 500);
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll(event: any) {
    this.isSticky = event.target.scrollTop >= 70;
  }

  confirm(event, menuItem) {
    if (event && (!menuItem.hasOwnProperty('disabled') || menuItem.disabled)) {
      menuItem.click();
    }
  }

  ngAfterViewInit(): void {
    /*const drawerContent = $('.mat-drawer-content');

     if ($('header .logo').length > 0) {
     TweenLite.to(
     $('header .logo'),
     .7,
     {
     delay: .5,
     ease: Back.easeInOut,
     css: {
     transform: 'scale(1)',
     opacity: 1
     },
     }
     );
     }

     if ($('header .logo span').length > 0) {
     TweenLite.to(
     $('header .logo span'),
     1.2,
     {
     delay: .5,
     ease: Expo.easeInOut,
     css: {
     opacity: 1,
     right: '16px'
     },
     }
     );
     }

     drawerContent.on('scroll', (e) => {
     if (e.originalEvent.target.scrollTop > 90) {
     if (!drawerContent.hasClass('scrolled')) {
     drawerContent.addClass('scrolled');

     if ($('header .logo span').length > 0) {
     TweenLite.to(
     $('header .logo span'),
     0,
     {
     delay: .2,
     ease: Back.easeInOut,
     css: {
     opacity: 1,
     right: '12px'
     },
     }
     );
     }
     }
     } else {
     if (drawerContent.hasClass('scrolled')) {
     drawerContent.removeClass('scrolled');
     if ($('header .logo span').length > 0) {
     TweenLite.to(
     $('header .logo span'),
     0,
     {
     delay: .2,
     ease: Back.easeInOut,
     css: {
     opacity: 1,
     right: '16px'
     },
     }
     );
     }
     }
     }
     });*/
  }

  /*breadcrumbs(breadcrumb) {
   console.log(breadcrumb);
   }

   setScreen() {
   if (window.innerWidth > 996) {
   this.drawer.open().then();
   this.layoutService.lockSidebar();
   this.layoutService.drawerIsOpen = true;
   } else {
   this.drawer.close().then();
   this.layoutService.unlockSidebar();
   this.layoutService.drawerIsOpen = false;
   }
   }*/

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  formTypeChanged(createAccount: MatSlideToggleChange) {
    this.createAccount = createAccount.checked;
  }

  registerAnimation() {
    if (this.fabTogglerState === 'active') {
      this.fabTogglerState = 'inactive';
    }
    else {
      this.fabTogglerState = 'active';
      $('.quick-register button .material-icons').removeClass('animate-icon');
    }
  }

  register() {
    this.myService.addNewsletter({
      email: this.registerForm.value.email,
    })
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(result => {
        console.log(result);
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.device = window.innerWidth > 996 ? 'desktop' : 'mobile';
  }

  ngOnDestroy() {
    // this.marquee?.marquee('destroy');
    clearTimeout(this.snackBarTimeout);
    clearTimeout(this.scrollTimeout);
    this.destroySubject$.next();
  }
}
