import pkg from '../../package.json';
import moment from 'moment';
import {Environment} from '../app/interfaces/DataModel';

export const env: Environment = {
  production: true,
  version: pkg.version,
  cacheBusterHash: moment().format('DD-MM-YYYY-h:mm:ss'),
  appUuid: '70071d7e-06d3-499f-b0d0-f271ea024d8d',
  // socket: 'https://api.90fit.com.tr',
  baseHref: 'https://90fit.com.tr/',
  adminUrl: 'https://admin.90fit.com.tr/',
  socketServiceHost: '90fit.com.tr:6001',
  apiUrl: 'https://api.90fit.com.tr/api/',
  baseApiUrl: 'https://api.90fit.com.tr/',
};

export const environment = Object.assign({}, env, {
  services: {
    authService: env.apiUrl + 'auth/',
    contentService: env.apiUrl + 'content/',
    contentCommentService: env.apiUrl + 'contentComments/',
    commentService: env.apiUrl + 'comment/',
    likeService: env.apiUrl + 'like/',
    userProgramService: env.apiUrl + 'userProgram/',
    articleService: env.apiUrl + 'article/',
    productService: env.apiUrl + 'product/',
    blogService: env.apiUrl + 'blog/',
    authorService: env.apiUrl + 'author/',
    contentCategoryService: env.apiUrl + 'contentCategory/',
    userService: env.apiUrl + 'user/',
    phoneService: env.apiUrl + 'phone/',
    menuService: env.apiUrl + 'menu/',
    menuVersionService: env.apiUrl + 'menuVersion/',
    eatService: env.apiUrl + 'eat/',
    materialService: env.apiUrl + 'material/',
    materialPropertyService: env.apiUrl + 'materialProperty/',
    orderService: env.apiUrl + 'order/',
    savedDataService: env.apiUrl + 'savedData/',
    completedDayService: env.apiUrl + 'completedDay/',
    copyMenuService: env.apiUrl + 'copyMenu/',
    menuNoteService: env.apiUrl + 'menuNote/',
    materialNoteService: env.apiUrl + 'materialNote/',
    eatMaterialService: env.apiUrl + 'eatMaterial/',
    excelService: env.apiUrl + 'excel/',
    wpOrderService: env.apiUrl + 'wp-order/',
    orderItemService: env.apiUrl + 'orderItem/',
    courierCompanyService: env.apiUrl + 'courierCompany/',
    myService: env.apiUrl + 'my/',
    sendMenusService: env.apiUrl + 'sendMenus/',
    eatRecipeService: env.apiUrl + 'eatRecipe/',
    accountService: env.apiUrl + 'account/',
    practicalScaleService: env.apiUrl + 'practicalScale/',
    productItemService: env.apiUrl + 'productItem/',
    productItemTypeService: env.apiUrl + 'productItemType/',
    productGroupService: env.apiUrl + 'productGroup/',
    productGroupPropertyService: env.apiUrl + 'productGroupProperty/',
    fileService: env.apiUrl + 'file/',
    watchService: env.apiUrl + 'watch/',
    newsletterService: env.apiUrl + 'newsletter/',
    shoppingCartService: env.apiUrl + 'shoppingCart/',
    shoppingCartItemService: env.apiUrl + 'shoppingCartItem/',
    systemService: env.apiUrl + 'system/',
    couponCodeService: env.apiUrl + 'couponCode/',
    paymentTypeService: env.apiUrl + 'paymentType/',
    foodDeliveryAreaService: env.apiUrl + 'foodDeliveryArea/',
    countryService: env.apiUrl + 'country/',
    cityService: env.apiUrl + 'city/',
    districtService: env.apiUrl + 'district/',
    townService: env.apiUrl + 'town/',
    neighborhoodService: env.apiUrl + 'neighborhood/',
    addressService: env.apiUrl + 'address/',
    paymentProfileService: env.apiUrl + 'paymentProfile/',
    creditCardService: env.apiUrl + 'creditCard/',
    exampleMenuService: env.apiUrl + 'exampleMenu/',
    oneSignalService: env.apiUrl + 'oneSignal/',
    defaultProductOptionsService: env.apiUrl + 'defaultProductOptions/',
    pushNotificationService: env.apiUrl + 'pushNotification/',
    repeatOrderService: env.apiUrl + 'repeatOrder/',
    friendsInvitationService: env.apiUrl + 'friendsInvitation/',
    programService: env.apiUrl + 'program/',
    userHealthService: env.apiUrl + 'userHealth/',
    dietitianAppointmentService: env.apiUrl + 'dietitianAppointment/',
    userProgramQuestionService: env.apiUrl + 'userProgramQuestion/',
    globalAssetService: env.apiUrl + 'globalAsset/',
    adsCampaignService: env.apiUrl + 'adsCampaign/',
  }
});
