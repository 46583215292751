import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ShoppingCartService} from '../shopping-cart.service';
import {ConfirmDialog, ShoppingCart, ShoppingCartItem} from '../../../interfaces/DataModel';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, first, takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ScopeService} from '../../../services/scope.service';
import {fadeAnimation} from '../../../animations/system.animation';
import {MdePopoverTrigger} from '@material-extended/mde';
import {PerfectScrollbarDirective} from 'ngx-perfect-scrollbar';
import {MatDialog} from '@angular/material/dialog';
import {NotificationService} from '../../../services/notification.service';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {Router} from '@angular/router';
declare var $;

@Component({
  selector: 'app-shopping-cart-icon',
  templateUrl: './shopping-cart-icon.component.html',
  styleUrls: ['./shopping-cart-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeAnimation
  ]
})
export class ShoppingCartIconComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() badge: boolean = true;
  shoppingCart: ShoppingCart;
  destroySubject$: Subject<any> = new Subject<any>();
  shoppingCartForm: FormArray;
  form: FormGroup;
  @ViewChild(MdePopoverTrigger, {static: false}) trigger: MdePopoverTrigger;
  /*@ViewChild('psList', {static: false}) directiveRef: PerfectScrollbarDirective;
  perfectScrollbar #psList="ngxPerfectScrollbar"*/
  couponCode = null;
  couponCodes: ShoppingCartItem[] = [];
  removeShoppingCartProductConfirm: ConfirmDialog;
  removeShoppingCartCouponConfirm: ConfirmDialog;
  couponCodeDialog: any;
  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

  constructor(public shoppingCartService: ShoppingCartService,
              public translateService: TranslateService,
              private snackBar: NotificationService,
              private formBuilder: FormBuilder,
              public scopeService: ScopeService,
              private dialog: MatDialog,
              private router: Router,
              private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      items: this.formBuilder.array([])
    });
    this.shoppingCartForm = this.form.get('items') as FormArray;

    this.translateService.get([
      'removeShoppingCartCouponConfirm',
      'removeShoppingCartProductConfirm',
    ])
    .pipe(takeUntil(this.destroySubject$))
    .subscribe(text => {
      this.removeShoppingCartCouponConfirm = {
        title: text.removeShoppingCartCouponConfirm.title,
        description: text.removeShoppingCartCouponConfirm.description,
        okButtonText: text.removeShoppingCartCouponConfirm.okButtonText,
      };
      this.removeShoppingCartProductConfirm = {
        title: text.removeShoppingCartProductConfirm.title,
        description: text.removeShoppingCartProductConfirm.description,
        okButtonText: text.removeShoppingCartProductConfirm.okButtonText,
      };
    });

    this.shoppingCartService.shoppingCart$
    .pipe(takeUntil(this.destroySubject$))
    .subscribe(shoppingCart => {
      if (shoppingCart && shoppingCart.hasOwnProperty('id')) {
        this.shoppingCart = shoppingCart;
        let products;
        [products, this.couponCodes] = this.scopeService.shoppingListParser(this.shoppingCart.shoppingCartItems);

        this.addShoppingCartItem(products);

        if (this.trigger?.popoverOpen &&
          shoppingCart.shoppingCartItems?.length === 0) {
          this.trigger?.closePopover();
        }

        if (!this.trigger?.popoverOpen && !this.shoppingCartService.firstLoad &&
          shoppingCart.shoppingCartItems?.length > 0) {
          this.trigger?.openPopover();
        }

        this.shoppingCartService.firstLoad = false;

        /*setTimeout(() => {
          if (this.directiveRef) {
            this.directiveRef.scrollToBottom(null, 300);
          }
        }, 200);*/

        if (this.shoppingCartService.lastAddedProductItemId) {
          const selector = this.shoppingCartForm?.controls
          .findIndex(x => x.value.productItemId === this.shoppingCartService.lastAddedProductItemId);

          if (selector) {
            setTimeout(() => {
              const item = $('.mini-shopping-cart-item').eq(selector);
              $('.mini-scrollbar').animate({
                scrollTop: item.position()?.top
              }, 600);
            }, 500);
          }
        }

      } else {
        while (this.shoppingCartForm.length !== 0) {
          this.shoppingCartForm.removeAt(this.shoppingCartForm.length - 1);
        }
      }

      this.cd.detectChanges();
    });
  }

  ngAfterViewInit() {
    this.shoppingCartService.shoppingCartPopover = this.trigger;
  }

  closePopover() {
    if (this.trigger?.popoverOpen) {
      this.trigger?.closePopover();
    }
  }

  addShoppingCartItem(shoppingCartItems: ShoppingCartItem[]) {
    while (this.shoppingCartForm.length !== 0) {
      this.shoppingCartForm.removeAt(this.shoppingCartForm.length - 1);
    }

    shoppingCartItems.map(shoppingCartItem => {
      const formGroup: FormGroup = this.formBuilder.group({
        quantity: [null, [
          Validators.required,
          Validators.max(shoppingCartItem.productItem.maxQuantity),
          Validators.min(1)
        ]],
        id: [null, Validators.required],
        productItemId: [null, Validators.required],
      });
      formGroup.patchValue(shoppingCartItem);

      formGroup.controls.quantity.valueChanges
      .pipe(takeUntil(this.destroySubject$))
      .pipe(
        debounceTime(800),
        distinctUntilChanged()
      )
      .subscribe((quantity) => {
        this.shoppingCartService.updateShoppingCart({
          id: formGroup.controls.id.value,
          quantity,
        })
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(() => {
        }, (err) => {
          this.shoppingCartService
          .getShoppingCart()
          .pipe(takeUntil(this.destroySubject$))
          .subscribe();
        });
      });

      this.shoppingCartForm.push(formGroup);
    });
  }

  setCouponCode() {
    if (!this.couponCode) {
      return;
    }

    this.shoppingCartService
    .setCouponCode(this.couponCode)
    .pipe(takeUntil(this.destroySubject$))
    .subscribe(() => {
      this.couponCodeDialog.close();

      this.shoppingCartService
      .getShoppingCart()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe();
    });
  }

  removeShoppingCart(confirm: boolean, shoppingCartId: number) {
    if (confirm) {
      this.shoppingCartService
      .removeShoppingCart(shoppingCartId)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe();
    }
  }

  removeQuantity(shoppingCartItem: ShoppingCartItem) {
    const row = this.shoppingCartForm.controls.find(scf => scf.value.id === shoppingCartItem.id);

    if (row.value.quantity === 1) {
      return this.removeShoppingCart(true, shoppingCartItem.id);
    }

    if (row.value.quantity > 1) {
      row.patchValue({
        quantity: row.value.quantity - 1
      });
    }
  }

  async addQuantity(shoppingCartItem: ShoppingCartItem) {
    const row = this.shoppingCartForm.controls.find(scf => scf.value.id === shoppingCartItem.id);

    if (shoppingCartItem.productItem.maxQuantity === 0 ||
      (shoppingCartItem.productItem.maxQuantity > 0 &&
        shoppingCartItem.quantity < shoppingCartItem.productItem.maxQuantity)) {

      row.patchValue({
        quantity: row.value.quantity + 1
      });
    } else {
      this.snackBar.error(
        await this.translateService.get('shopping-cart.max-quantity-error', {
          max: shoppingCartItem.productItem.maxQuantity
        }).toPromise()
      );
    }
  }

  goToPayment() {
    this.router.navigateByUrl('/payment').then(() => {
      this.cd.detectChanges();
    });
  }

  openDialogWithRef(templateRef: TemplateRef<any>) {
    this.couponCodeDialog = this.dialog.open(templateRef, {
      panelClass: 'no-padding-dialog',
      minWidth: '300px',
      disableClose: true,
    });
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.trigger.destroyPopover();
  }
}

