import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {LocalStorageService} from '../services/local-storage.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExternalLoginGuard implements CanActivate {

  constructor(private localStorage: LocalStorageService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const url = (new URL(window.location.href));

    if (url.searchParams.has('auth')) {
      const auth = JSON.parse(decodeURIComponent(escape(atob(url.searchParams.get('auth')))));
      this.localStorage.setItem('auth', auth);
      url.searchParams.delete('auth');
      window.location.href = url.toString();
      return false;
    }

    return true;
  }
}
