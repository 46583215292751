import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TruncatePipe} from './truncate.pipe';
import {SafeHtmlPipe} from './safe-html.pipe';
import { TitleCaseUtf8Pipe } from './title-case-utf8.pipe';


@NgModule({
  declarations: [
    TruncatePipe,
    SafeHtmlPipe,
    TitleCaseUtf8Pipe,
  ],
  exports: [
    TruncatePipe,
    SafeHtmlPipe,
    TitleCaseUtf8Pipe,
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule {
}
