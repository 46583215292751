import {Injectable} from '@angular/core';
import {LocalStorageService} from '../../services/local-storage.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, map, share} from 'rxjs/operators';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {ShoppingCartRequest} from '../../interfaces/Requests';
import {ShoppingCart} from '../../interfaces/DataModel';
import {MdePopoverTrigger} from '@material-extended/mde';
import {FacebookPixelService} from '../../services/facebook-pixel.service';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  shoppingCart$: BehaviorSubject<ShoppingCart> = new BehaviorSubject<ShoppingCart>(null);
  shoppingCartPopover: MdePopoverTrigger;
  firstLoad: boolean = true;
  lastAddedProductItemId: number;
  layout: '90fit' | 'eattkit';

  constructor(private localStorage: LocalStorageService,
              private fps: FacebookPixelService,
              private http: HttpClient) {
  }

  addShoppingCart(shoppingCart: ShoppingCartRequest):
    Observable<ShoppingCart> {

    this.firstLoad = false;

    if (!shoppingCart.hasOwnProperty('quantity')) {
      shoppingCart.quantity = 1;
    }
    if (!shoppingCart.hasOwnProperty('empty')) {
      shoppingCart.empty = false;
    }
    if (!shoppingCart.hasOwnProperty('session')) {
      shoppingCart.session = this.localStorage.getItem('system.session');
    }

    return this.http.post<ShoppingCart>(environment.services.shoppingCartItemService, shoppingCart)
    .pipe(
      catchError((error) => {
        return throwError(error);
      })
    )
    .pipe(
      map((obj) => {
        this.lastAddedProductItemId = shoppingCart.productItemId;
        this.shoppingCart$.next(obj);

        this.fps.track('AddToCart', {
          value: this.shoppingCart$.getValue().price,
          currency: 'TRY',
          content_ids: this.shoppingCart$.getValue().shoppingCartItems
          .filter(x => x.rowType === 'product').map(x => x.productItem.product.id),
          content_type: 'product',
        });

        return obj;
      })
    )
    .pipe(share());
  }

  updateShoppingCart(shoppingCart: ShoppingCartRequest):
    Observable<ShoppingCart> {

    this.firstLoad = false;

    return this.http.put<ShoppingCart>(environment.services.shoppingCartItemService + shoppingCart.id + '?session=' +
      this.localStorage.getItem('system.session'), shoppingCart)
    .pipe(
      map((obj) => {
        this.lastAddedProductItemId = shoppingCart.productItemId;
        this.shoppingCart$.next(obj);
        return obj;
      })
    )
    .pipe(share());
  }

  /*checkShoppingCartItem(productItem: ProductItem):
    boolean {
    const shoppingCart: ShoppingCartItem[] = this.shoppingCart();
    return shoppingCart.some(sci => sci.productItem.id === productItem.id);
  }*/

  getShoppingCart():
    Observable<ShoppingCart> {
    return this.http.get<ShoppingCart>(environment.services.shoppingCartService + '?session=' +
      this.localStorage.getItem('system.session'))
    .pipe(
      map((obj) => {
        this.shoppingCart$.next(obj);
        return obj;
      })
    )
    .pipe(share());
  }

  setCouponCode(couponCode: string):
    Observable<ShoppingCart> {
    return this.http.post<ShoppingCart>(environment.services.couponCodeService, {
      session: this.localStorage.getItem('system.session'),
      couponCode
    })
    .pipe(
      map((obj) => {
        // this.shoppingCart$.next(obj);
        return obj;
      })
    )
    .pipe(share());
  }

  removeShoppingCart(id: number):
    Observable<ShoppingCart> {

    this.firstLoad = false;

    return this.http.delete<ShoppingCart>(environment.services.shoppingCartItemService + id + '?session=' +
      this.localStorage.getItem('system.session'))
    .pipe(
      map((obj) => {
        this.lastAddedProductItemId = null;
        this.shoppingCart$.next(obj);
        return obj;
      })
    )
    .pipe(share());
  }

  emptyShoppingCart():
    Observable<ShoppingCart> {

    this.firstLoad = false;

    return this.http.delete<ShoppingCart>(environment.services.shoppingCartItemService + '?session=' +
      this.localStorage.getItem('system.session'))
    .pipe(
      map((obj) => {
        this.shoppingCart$.next(obj);
        return obj;
      })
    )
    .pipe(share());
  }
}
